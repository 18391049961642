import { useEffect, useMemo, useState } from 'react';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { useForm, Controller } from "react-hook-form";
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import CardLayout from '../../components/utils/CardLayout'
import Button from '../../components/utils/Button'
import Table from '../../components/utils/Table';
import { isKeyUnique, validateIMEI, validateSNo, validatePin, selectCharacters, twoDigitDecimal } from '../../helpers/commonFunction';
import activationRequestActions from '../../redux/activationRequest/actions';
import './ActivationRequest.css';
import Alert from '../../components/utils/Alert';
import planAction from '../../redux/plan/actions';
import shortCodeAction from '../../redux/shortCodeData/actions';

const { addMultiLineBulkActivation, resetValidateMessage, getDealerPostFixEmail, getPrepaidIqAddresses, validateSerialNumber } = activationRequestActions;
const BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

const { getPlan } = planAction;
const { getSimType } = shortCodeAction;

const MultilineBulkActivationForm = ({ back, searchData }) => {
    const dispatch = useDispatch();
    const { networkResult } = useSelector((state) => state.network);
    const { planResult } = useSelector((state) => state.plan);
    const { tokenData } = useSelector((state) => state.auth);
    const { shortCodeSimType } = useSelector((state) => state.shortCode);
    const { planAddonResult, addMultiLineBulkActivationMessage, addMultiLineBulkActivationError, addMultiLineBulkActivationLoading, getPrepaidIqAddressesData, validateSerialNumberData, validateSerialNumberLoading } = useSelector((state) => state.activationRequest);
    const { addonResult } = useSelector((state) => state.addonService);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [planAddonResultData, setPlanAddonResultData] = useState([]);
    const [addOnSelectMultiple, setAddOnSelectMultiple] = useState(false);
    const [planAddonSelectedOption, setPlanAddonSelectedOption] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [previewSuccessData, setPreviewSuccessData] = useState([]);
    const [previewFailedData, setPreviewFailedData] = useState([]);
    const [showPreviewType, setShowPreviewType] = useState(2);
    const [activationRequests, setActivationRequests] = useState([]);
    const [previewSuccessCount, setPreviewSuccessCount] = useState(0);
    const [csvError, setCsvError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [networkId, setNetworkId] = useState(0);
    const [prefferAggId, setPrefferAggId] = useState(0);
    const [monthList, setMonthList] = useState([]);
    const [accountBalance, setAccountBalance] = useState(0);
    const [simType, setSimType] = useState(0);
    const [apiCall, setApiCall] = useState(false);

    useEffect(() => {
        tokenData && tokenData.length > 0 && tokenData.map((data) => {
            setAccountBalance(data.AccountBalance);
        });
    }, [tokenData]);

    useEffect(() => {
        dispatch(getDealerPostFixEmail());
        dispatch(getSimType({
            tableName: "activationrequest",
            fieldName: "SimType"
        }));
    }, []);

    useEffect(() => {
        if (csvError !== null && csvError !== undefined) {
            displayAlert(csvError, 'alert-danger');
        }
    }, [csvError]);

    useEffect(() => {
        if (addMultiLineBulkActivationMessage || addMultiLineBulkActivationError) {
            back();
            dispatch(resetValidateMessage());
        }
    }, [addMultiLineBulkActivationMessage, addMultiLineBulkActivationError]);

    // useEffect(() => {
    //     if (previewData.length > 0) {
    //         let successPreview = previewData.filter((item) => item.Status == 1);
    //         let failedPreview = previewData.filter((item) => item.Status == 0);
    //         setPreviewSuccessData(successPreview);
    //         setPreviewFailedData(failedPreview);
    //     }
    // }, [previewData]);

    useEffect(() => {
        let selectedNetwork = networkResult && networkResult.filter(network => network.ID == networkId);
        dispatch(getPlan(networkId));
    }, [networkId]);

    useEffect(() => {
        if (planResult.length > 0 && networkResult.length > 0) {
            let multiLinePlans = planResult.filter((item) => item.MultilineFlag === 1);
            setSelectedPlan(multiLinePlans);
        } else {
            setSelectedPlan([]);
        }
    }, [planResult]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }

    const {
        register: bulkActivationRegister,
        handleSubmit: bulkActivationHandleSubmit,
        formState: { errors: bulkActivationError },
        watch: bulkActivationWatch,
        reset: bulkActivationReset,
        setValue: bulkActivationSetValue,
        setError: bulkActivationSetError,
        clearErrors: bulkActivationClearErrors,
        control: bulkActivationcontrol, } = useForm({
            defaultValues: {
                networkID: '',
                planID: '',
                month: '',
                address1: '',
                state: '',
                simType: '',
            }
        });

    const selectedAddon = bulkActivationWatch('serviceID');
    const state = bulkActivationWatch('state');
    const simTypeWached = bulkActivationWatch('simType');
    const monthWached = bulkActivationWatch('month');
    const planIDWached = bulkActivationWatch('planID');

    useEffect(() => {
        if (simTypeWached) {
            setSimType(simTypeWached);
        }
    }, [simTypeWached]);

    useEffect(() => {
        if (simType > 0) {
            bulkActivationReset({ uploadedFile: '' });
            setPreviewData([]);
        }
    }, [simType]);


    const stateOptions = useMemo(() => {
        if (getPrepaidIqAddressesData.length > 0) {
            return [...new Set(getPrepaidIqAddressesData.map(item => item.State))];
        } else {
            return [];
        }
    }, [getPrepaidIqAddressesData]);

    useEffect(() => {
        if (state) {
            let dataOfThisState = getPrepaidIqAddressesData.filter((item) => item.State === state);
            let randomData = dataOfThisState[(Math.floor(Math.random() * dataOfThisState.length))];
            bulkActivationSetValue('address1', randomData.Address);
            bulkActivationSetValue('address2', randomData.City);
            bulkActivationSetValue('wifiZipCode', randomData.ZIP);
        }
    }, [state]);

    useEffect(() => {
        if (validateSerialNumberData.length > 0) {
            setApiCall(false);
            let newDataSource = previewData.map((item) => {
                let count = validateSerialNumberData.filter((item1) => item1.SerialNumber == item.SerialNumber);
                if (count.length > 0) {
                    if (count[0].Status === 0) {
                        item.Status = 0;
                    }
                    item.Reason = count[0].ExistStatus;
                }
                return item;
            });
            setPreviewData(newDataSource);

            let successCount = validateSerialNumberData.filter((item) => item.Status === 1);
            setPreviewSuccessCount(successCount.length);
            // For API Request
            let newDataRequest = activationRequests.map((item) => {
                let count = validateSerialNumberData.filter((item1) => item1.SerialNumber == item.simNumber);
                if (count.length > 0) {
                    if (count[0].Status === 0) {
                        item.status = 0;
                        item.reason = count[0].ExistStatus;
                    }
                }
                return item;
            });
            setActivationRequests(newDataRequest);
        }
    }, [validateSerialNumberData]);

    useEffect(() => {
        if (apiCall && previewData.length > 0) {
            let validData = previewData.filter((item) => item.Status == 1);
            if (validData.length > 0) {
                let serial_number_array = validData.map((item) => item.SerialNumber);
                dispatch(validateSerialNumber(serial_number_array));
            }
        }
    }, [previewData]);

    const selectOptions = planAddonResultData && planAddonResultData.length > 0 ? planAddonResultData.map(addon => ({
        label: addon.ServiceName,
        value: addon.AddonID,
        tmobileAddonId: addon.TMobileAddonID
    })) : [];


    const previewColumns = [
        { displayName: 'IMEI', key: 'IMEI' },
        { displayName: 'SERIAL NUMBER', key: 'SerialNumber' },
        { displayName: 'REASON', key: 'Reason' },
        { displayName: 'VALID', key: 'Valid' },
    ];

    const previewDataSource = previewData && previewData.length > 0 && previewData.map((item) => {
        return {
            IMEI: item.IMEI,
            SerialNumber: item.SerialNumber,
            Reason: item.Reason,
            Valid: item.Status ? <CheckLineIcon color='#2ecc71' /> : <CloseLineIcon color='#e74c3c' />
        }
    });

    const downloadSampleFile = () => {
        let url = `${BASE_URL}/assets/csv/prepaidIQ-bulk-activation-sample-file.csv`;
        let link = document.createElement('a');
        link.href = url;
        link.download = 'sample-bulk-activation-file.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleNetwork = (e) => {
        e.preventDefault();
        bulkActivationReset({
            planID: '',
            serviceID: '',
            uploadedFile: '',
            month: '',
        });
        setPreviewData([]);
        // setPreviewSuccessData([]);
        setPreviewFailedData([]);
        dispatch(getPlan(e.target.value));
        setNetworkId(e.target.value);
    }

    const handleSelectPlan = (e) => {
        bulkActivationReset({
            serviceID: '',
            month: '',
        });
        const singlePlanCategory = ['Connect by T-Mobile', 'Mobile Internet Plans'];
        const multiPlanCategory = ['T-Mobile Prepaid'];
        const planId = e.target.value;
        if (planId !== null && planId !== "") {
            const selectedData = planResult && planResult.filter(plan => plan.PlanID == planId);
            const planAddon = planAddonResult && planAddonResult.filter(item => item.PlanID == planId)
            setPlanAddonResultData(planAddon)
            bulkActivationSetValue('aggregatorID', selectedData[0].PrefferedAggregator);
            setPrefferAggId(selectedData[0].PrefferedAggregator);

            dispatch(getPrepaidIqAddresses({
                prefferedAggregator: selectedData[0].PrefferedAggregator
            }));
            bulkActivationSetValue('state', '');
            bulkActivationSetValue('address1', '');
            bulkActivationSetValue('city', '');
            bulkActivationSetValue('zipCode', '');

            let mon = selectedData[0].PlanMonth;
            setMonthList(JSON.parse(mon));

            if (selectedData[0].PlanCategory) {
                if (singlePlanCategory.includes(selectedData[0].PlanCategory)) {
                    setAddOnSelectMultiple(false);
                } else if (multiPlanCategory.includes(selectedData[0].PlanCategory)) {
                    setAddOnSelectMultiple(true);
                }
                setPlanAddonSelectedOption(true);
            } else {
                setPlanAddonSelectedOption(false)
            }
            // setPlanAmount(selectedData[0].PlanPrice);
            // bulkActivationSetValue('tMobilePlanID', selectedData[0].TMobilePlanID)
        }
    }

    const handleSelectAddon = (e) => {
        let valuesArray = [];
        let tMobileArray = [];

        if (addOnSelectMultiple || true) {
            valuesArray = e.map(item => item.value);
            tMobileArray = e.map(item => item.tmobileAddonId)
        } else {
            valuesArray = [e.value];
            tMobileArray = [e.tmobileAddonId];
        }

        bulkActivationSetValue('serviceID', valuesArray);
        bulkActivationSetValue('tMobileAddonID', tMobileArray);

        const resultServiceArray = valuesArray.map(id => {
            const service = addonResult.find(item => item.ID === id);
            return service ? { id, ServiceName: service.ServiceName, ServicePrice: service.ServicePrice } : null;
        });

        const filteredResultServiceArray = resultServiceArray.filter(service => service !== null);
        const totalServicePrice = filteredResultServiceArray.reduce((total, service) => total + (service.ServicePrice || 0), 0);

    }

    const handlePreviewTableData = (id) => {
        setShowPreviewType(id)
    }

    const handleFileChange = (e) => {
        bulkActivationClearErrors(['uploadedFile']);
        setPreviewData([]);
        setActivationRequests([]);
        setCsvError(null);
        alertCancel();
        const fileReader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            bulkActivationSetError("uploadedFile", { type: "custom", message: "Csv is required!" })
            return;
        } else {
            let fileNameArr = file.name.split('.');
            let fileExt = fileNameArr[fileNameArr.length - 1];

            if (!["csv"].includes(fileExt.toLowerCase())) {
                bulkActivationSetError("uploadedFile", { type: "custom", message: "Invalid file type. Only CSV file allowed." })
                return;
            }
        }

        if (file) {
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true, skipEmptyLines: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);

                if (parsedData.length > 500) {
                    setCsvError('Maximum 500 rows are allowed');
                    return;
                } else if (parsedData.length % 5 != 0) {
                    setCsvError('In a CSV file, rows need to divide by 5');
                    return;
                }

                const specifiedColumn = ['IMEI', 'SerialNumber'];
                const isValidCsvHeading = specifiedColumn.every((item) => columns.includes(item));

                if (isValidCsvHeading) {
                    let dataSource = parsedData.map((item) => {
                        let error = false;
                        let reason = "";

                        if (!validateIMEI(item.IMEI.slice(0, 15))) {
                            reason = "Invalid IMEI";
                            error = true;
                        } else if (!validateSNo(selectCharacters(item.SerialNumber, networkId), networkId, simType)) {
                            reason = simType == 25 ? "Invalid Serial Number" : "Serial Number not allowed for e-sim";
                            error = true;
                        }

                        return {
                            IMEI: item.IMEI.slice(0, 15),
                            SerialNumber: selectCharacters(item.SerialNumber, networkId),
                            Reason: reason,
                            Status: error ? 0 : 1
                        }
                    });

                    if (!isKeyUnique(dataSource, 'SerialNumber')) {
                        bulkActivationSetError("uploadedFile", { type: "custom", message: "Csv contain duplicate sim number" })
                        return false;
                    }
                    setPreviewData(dataSource);
                    setApiCall(true);

                    let requestData = parsedData.map((item) => {
                        return {
                            imeiNumber: item.IMEI.slice(0, 15),
                            simNumber: selectCharacters(item.SerialNumber, networkId),
                        }
                    });
                    setActivationRequests(requestData);

                } else {
                    bulkActivationSetError("uploadedFile", { type: "custom", message: "Invalid Csv Selected" })
                }
            };
            fileReader.readAsText(file);
        }
    }

    const handleAddBulkActivation = (data) => {
        let activationData = [];
        let requestActivation = [];

        for (let i = 0; i < activationRequests.length; i += 5) {
            const group = activationRequests.slice(i, i + 5);
            activationData.push(group);
        }

        activationData = activationData.forEach((item) => {
            let tempObj = new Object();
            tempObj.imeiNumber = item[0].imeiNumber;
            tempObj.simNumber = item[0].simNumber;
            item.shift();
            tempObj.childData = item;
            requestActivation.push(tempObj)
        });

        data.Pin = data.Pin ? data.Pin : '';
        data.wifiCalling = 1;
        data.fname = 'John';
        data.lname = 'Doe';
        data.totalRequests = activationRequests.length

        let approvedRows = previewData.filter((item) => item.Status == 1);
        data.successRequests = approvedRows.length;

        let failedRows = previewData.filter((item) => item.Status == 0);
        data.failedRequestData = failedRows
        data.failedRequest = failedRows.length

        data.activationRequests = requestActivation;

        data.address2 = data.address2 ? data.address2 : '';
        data.wifiZipCode = data.wifiZipCode ? data.wifiZipCode : '';
        // data.address2 = `${data.address2} ${data.wifiZipCode}`
        data.address2 = `${data.address2}`
        delete data.uploadedFile;
        // delete data.wifiZipCode;

        const selectedPlan = planResult.filter((item) => item.PlanID == data.planID);
        const priceObject = JSON.parse(selectedPlan[0].PlanMonth).filter((item) => item.month == data.month);
        const childPrice = selectedPlan[0].ChildPrice;

        if (!data.serviceID || !data.serviceID.length) {
            data.serviceID = [];
        }

        const selectedAddOn = addonResult.filter((item) => data.serviceID.includes(item.ID));
        let planAddOnTotal = 0;
        selectedAddOn.forEach(item => {
            planAddOnTotal += item.ServicePrice
        });

        const parentCount = activationRequests.length * (20 / 100);
        const childCount = activationRequests.length * (80 / 100);

        const Total = priceObject[0].price;
        data.purchaseAmount = (Total * parentCount) + (childPrice * childCount);

        if (failedRows.length > 0) {
            alert('One of your entry is invalid please correct it & try again.');
        } else {
            if (accountBalance >= data.purchaseAmount) {
                // console.log('data ', data);
                dispatch(addMultiLineBulkActivation(data, searchData));
            } else {
                alert('insufficient fund');
            }
        }

    }

    // SHOW CALCULATED AMOUNT TABLE
    const estimatedAmountColumn = [
        { displayName: 'Total Activation', key: 'tActivation' },
        { displayName: 'Per Activation', key: 'pActivation' },
        { displayName: 'Total Paid', key: 'tPaid' },
    ];

    const estimatedAmountDataSource = useMemo(() => {
        if (monthWached > 0 && planIDWached > 0 && previewData.length > 0) {
            const selectedPlan = planResult.filter((item) => item.PlanID == planIDWached);
            const priceObject = JSON.parse(selectedPlan[0].PlanMonth).filter((item) => item.month == monthWached);
            const Total = priceObject[0].price;
            const ChildPrice = selectedPlan[0].ChildPrice;
            const paidTotal = (Total * (previewData.length / 5)) + (ChildPrice * (previewData.length - (previewData.length / 5)));

            return [
                {
                    tActivation: `Parent - ${previewData.length / 5}, Child - ${previewData.length - (previewData.length / 5)}`,
                    pActivation: `Parent - $${twoDigitDecimal(priceObject[0].price)}, Child - $${twoDigitDecimal(ChildPrice)}`,
                    tPaid: `$${twoDigitDecimal(paidTotal)}`
                }
            ];

        } else {
            return [
                {
                    tActivation: 0,
                    pActivation: 0,
                    tPaid: 0
                }
            ];
        }
    }, [monthWached, planIDWached, previewData]);

    return (
        <CardLayout title="Add Multiline Bulk Activation">
            <div className="row mb-3">
                <div className='col-lg-12 text-right'>
                    <button type="button" onClick={() => downloadSampleFile()} style={{ background: '#2ecc71', color: '#fff', pointerEvents: 'auto' }} className={`btn font-weight-bold mr-2`}><DownloadCloud2sLineIcon size={16} />Sample File</button>
                    <button type="button" onClick={() => back()} className={`btn btn-danger font-weight-bold`}><ArrowLeftLineIcon size={16} />Back</button>
                </div>
            </div>

            <div className='container'>
                <form onSubmit={bulkActivationHandleSubmit(handleAddBulkActivation)}>
                    <div className="form-row">

                        <div className="col-md-3 mb-3">
                            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                            <select className="form-control" placeholder="Select Network" name="networkID"  {...bulkActivationRegister("networkID", {
                                required: 'Network is Required',
                            })} onChange={(e) => { handleNetwork(e) }} >
                                <option value="" key="0" disabled>Select</option>
                                {networkResult.length > 0 && networkResult.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                ))}
                            </select>
                            {bulkActivationError && bulkActivationError.networkID && (<div className="invalid-feedback">{bulkActivationError.networkID.message}</div>)}
                        </div>

                        <div className="col-md-3 mb-3">
                            <label className="ml-2"> Plan <span className='text-danger'>*</span></label>
                            <select className="form-control" placeholder="Select Plan" name="planID"  {...bulkActivationRegister("planID", {
                                required: 'Plan is Required',
                            })} onChange={(e) => handleSelectPlan(e)}>
                                <option value="" disabled key="0">Select</option>
                                {selectedPlan && selectedPlan.length > 0 && selectedPlan.map(plan => (
                                    <option name="plan" disabled={plan.ChildPrice === 0} value={plan.PlanID} key={plan.PlanID}>{`$${plan.PlanRentalPrice}`} - {plan.PlanName}</option>
                                ))}
                            </select>
                            {bulkActivationError && bulkActivationError.planID && (<div className="invalid-feedback">{bulkActivationError.planID.message}</div>)}
                        </div>

                        {/* plan addon commented for now */}
                        {/* {planAddonSelectedOption ? <div className="col-md-3 mb-3">
                            <label className="ml-2"> <span className='text-danger'></span> Addon Service </label>
                            <Controller
                                control={bulkActivationcontrol}
                                defaultValue={selectOptions.map(c => c.value)}
                                name="serviceID"
                                render={({ field: { onChange, value, ref } }) => (
                                    <ReactSelect
                                        className="basic-multi-select"
                                        placeholder="Select Service"
                                        value={selectOptions.filter(c => value.includes(c.value))}
                                        onChange={(e) => handleSelectAddon(e)}
                                        options={selectOptions}
                                        isOptionDisabled={() => !addOnSelectMultiple && selectedAddon && selectedAddon.length >= 1}
                                        isMulti
                                    />
                                )}
                            />
                            {bulkActivationError && bulkActivationError.serviceID && (<div className="invalid-feedback">{bulkActivationError.serviceID.message}</div>)}
                        </div> : <div></div>} */}

                        <div className="col-md-3 mb-3">
                            <label className="ml-2">Pin <span className='text-danger'> *</span></label>
                            <input type="text" placeholder="Enter Pin" disabled={networkId == 0} className="form-control" name="Pin" {...bulkActivationRegister("Pin", {
                                required: 'Pin Required',
                                validate: (val) => {
                                    return validatePin(val, networkId) === true ? true : networkId == 1 || networkId == 2 ? 'Six digit required' : networkId == 3 || networkId == 4 ? 'Four digit required' : 'Invalid pin';
                                }
                            })} />
                            {bulkActivationError && bulkActivationError.Pin && (<div className="invalid-feedback">{bulkActivationError.Pin.message}</div>)}
                        </div>

                        <div className="col-md-3 mb-3">
                            <label className="ml-2"> Month <span className='text-danger'>*</span></label>
                            <select className="form-control" name="month"
                                {...bulkActivationRegister("month", {
                                    required: 'Month is required !',
                                })}>
                                <option value="" key={`month`} disabled>Select</option>
                                {monthList.length > 0 && monthList.map((item, index) => {
                                    return <option value={item.month} key={`month${index}`}>{item.month}</option>
                                })}
                            </select>
                            {bulkActivationError && bulkActivationError.month && (<div className="invalid-feedback">{bulkActivationError.month.message}</div>)}
                        </div>
                    </div>

                    <div className="form-row border py-1 rounded my-2">

                        <div className="col-md-3 mb-3">
                            <label className="ml-2">State <span className='text-danger'> *</span></label>
                            <select className="form-control" name="state"
                                {...bulkActivationRegister("state", {
                                    required: 'State is required !',
                                })} >
                                <option value="" disabled>Select</option>
                                {stateOptions.length > 0 && stateOptions.map((item, index) => (
                                    <option value={item} key={`address${index}`}>{item}</option>
                                ))}
                            </select>
                            {bulkActivationError && bulkActivationError.state && (<div className="invalid-feedback">{bulkActivationError.state.message}</div>)}
                        </div>

                        <div className="col-md-3 mb-3">
                            <label className="ml-2">Sim Type <span className='text-danger'> *</span></label>
                            <select className="form-control" name="simType"
                                {...bulkActivationRegister("simType", {
                                    required: 'Sim type is required !',
                                })}>
                                <option value="" disabled>Select</option>
                                {
                                    shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.filter((item) => item.ID == 25).map((item) => {
                                        return <option value={item.ID} key={`simType${item.ID}`}>{item.Value}</option>
                                    })
                                }
                            </select>
                            {bulkActivationError && bulkActivationError.simType && (<div className="invalid-feedback">{bulkActivationError.simType.message}</div>)}
                        </div>
                        {
                            prefferAggId != 0 && (simType == 25 || simType == 26) ?
                                <div className="col-md-6 mb-3">
                                    <div className="input-group">
                                        <label htmlFor="csvFile"><span className='text-danger'>*</span>Upload File </label>
                                        <input
                                            type="file"
                                            className="form-control-file"
                                            name='uploadedFile'
                                            id="csvFile"
                                            accept='.csv'
                                            {...bulkActivationRegister('uploadedFile', { required: 'Csv is required' })}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                        <small className='text-danger'>Note : Only 500 Rows are allowed</small>
                                        {bulkActivationError && bulkActivationError.uploadedFile && (<div className="invalid-feedback">{bulkActivationError.uploadedFile.message}</div>)}

                                    </div>
                                </div> : ''
                        }
                    </div>


                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                    {
                        previewData.length > 0 &&
                        <>
                            <hr />
                            <div className="col-md-12 mb-3 p-0">
                                <Table columns={previewColumns} loading={validateSerialNumberLoading} data={previewDataSource} pagination={false} />
                            </div>
                            <div className="col-md-12 mb-3 p-0">
                                <h4>Price details</h4>
                                <Table columns={estimatedAmountColumn} loading={false} data={estimatedAmountDataSource} pagination={false} />
                            </div>
                        </>
                    }
                    <hr />

                    <div className='text-right row'>
                        <div className="col-md-12">
                            {
                                prefferAggId == null || prefferAggId == undefined ? <span className='text-danger mr-2 font-weight-bold'>Configuration not set, please contact admin</span> : ''
                            }
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => back()}>Close</button>
                            {previewData.length > 0 && previewData.length % 5 === 0 && previewData.filter((item) => item.Status === 0).length === 0 ? <Button title="Submit" style="minWidth:80px" loading={addMultiLineBulkActivationLoading} buttonClass="btn btn-primary" buttonType='submit' /> : ''}
                            
                        </div>
                    </div>

                </form>
            </div>

        </CardLayout>
    )
}

export default MultilineBulkActivationForm;