import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import DatePicker from "react-datepicker";
import Button from '../../components/utils/Button';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { set, useForm, Controller, useFieldArray } from "react-hook-form";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import Compressor from 'compressorjs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import SimCard2Line from 'remixicon-react/SimCard2LineIcon';
import CardLayout from '../../components/utils/CardLayout';
import SideButtons from '../../components/utils/SideButtons';
import SearchBar from '../../components/utils/SearchBar';
import "react-datepicker/dist/react-datepicker.css";
import activationRequestActions from '../../redux/activationRequest/actions';
import Table from '../../components/utils/Table';
import Modal from '../../components/utils/Model';
import planAction from '../../redux/plan/actions';
import AddonServiceActions from '../../redux/addonService/actions';
import networkActions from '../../redux/network/actions';
import AggregatorActions from '../../redux/aggregator/actions'
import authActions from '../../redux/auth/actions';
import Alert from '../../components/utils/Alert';
import Tag from '../../components/utils/Tag';
import { isDealer, isAdmin, isAdminAgent, validateIMEI, removeSameElementFromArray } from '../../helpers/commonFunction';
import Styles from './ActivationRequest.module.css';
import BulkActivationForm from './BulkActivationForm';
import SimSwapModalComponent from './SimSwapModal';
import shortCodeActions from '../../redux/shortCodeData/actions'
import dealerAction from '../../redux/dealer/actions'
import { convertDateTimeToRequiredFormatTZ } from '../../helpers/commonFunction';
import MultilineBulkActivationForm from './MultilineBulkActivationForm';

const TIMEZONE = process.env.REACT_APP_TIMEZONE;
const LONG_TIME_FORMAT = process.env.REACT_APP_LONG_TIME_FORMAT;

const { getShortCode, getSimType } = shortCodeActions;


function ActivationRequest() {
    const dispatch = useDispatch();
    const { getUserDataByToken } = authActions
    const { getAggregator } = AggregatorActions;
    const { getDealer } = dealerAction;
    const { getActivationRequestList, transactionIdModal, closeTransactionIdModal, prepareActivationModal, closeActivationModal, createActivation, lockActivation, unLockActivation, prepareCompleteModal, closeCompleteModal, accessTransactionModal, compeleteActivation, resetActivation, prepareConfirmationModal, prepareCancelModal, closeCancelModal, getErrorCode, cancelActivation, getPlanAddonData, prepareStatusModel, closeStatusModel, updateActivationStatus, validateImeiNumber, resetValidateMessage, openSimSwapModal, getPrepaidIqAddresses, updateActivationInBulk, backConfirmationModal, validateSerialNumber } = activationRequestActions;
    const { getPlan } = planAction;
    const { getActiveNetwork } = networkActions;
    const { getAddonService } = AddonServiceActions
    const { tokenData } = useSelector((state) => state.auth);
    const { addonResult } = useSelector((state) => state.addonService);
    const { planResult } = useSelector((state) => state.plan);
    const { networkResult } = useSelector((state) => state.network);
    const { list: aggregatorList } = useSelector((state) => state.aggregator);
    const { shortCodeResult, shortCodeSimType } = useSelector((state) => state.shortCode);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const { transectionIdModal, planAddonResult, activationMessage, activationError, activationRequestResult, buttonLoading,
        activationFormModal, completeModal, requestData, messageModal, apiErrorModal, confirmationModal, cancelModal, errorCodeResult,
        LockFailAlert, LockedFailedmessage, updateModal, validateSuccess, validateFailed, activationListLoading, addBulkActivationError,
        addBulkActivationMessage, simSwapModal, createSimSwapRequestError, createSimSwapRequestMessage, getPrepaidIqAddressesData,
        updateActivationInBulkMessage, updateActivationInBulkError, updateActivationInBulkLoading, addMultiLineBulkActivationMessage, addMultiLineBulkActivationError, modalViewLoader, validateSerialNumberData, validateSerialNumberLoading } = useSelector((state) => state.activationRequest);
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [enableCallingUI, setEnableCallingUI] = useState(false);
    const [enableMultiLineUi, setEnableMultiLineUi] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileImage, setSelectedFileImage] = useState(null);
    const [errorImage, setErrorImage] = useState("");
    const [editId, seteditId] = useState(null);
    const [disableEID, setDisableEID] = useState(false);
    const [planAddonSelectedOption, setPlanAddonSelectedOption] = useState(false);
    const [mobileDisable, setmobileDisable] = useState(false);
    const [chooseFileDisable, setchooseFileDisable] = useState(false);
    const [childDataDisp, setChildDataDisp] = useState('');
    const [modalActivationData, setModalActivationData] = useState(null);
    const [childFormDataShow, setchildFormDataShow] = useState()
    const [childDatashowFlag, setchildDatashowFlag] = useState(false);
    const [submitActivationData, setSubmitActivationData] = useState([])
    const [planAddonResultData, setPlanAddonResultData] = useState([])
    const [confirmationData, setConfirmationData] = useState([]);
    const [planAmount, setPlanAmount] = useState(0);
    const [addonAmount, setAddonAmount] = useState(0);
    const [childAmount, setChildAmount] = useState(0);
    const [multiAddonAmount, setMultiAddonAmount] = useState(0)
    const [formCount, setFormCount] = useState(1);
    const [accountBalance, setAccountBalance] = useState(0);
    const [addOnSelectMultiple, setAddOnSelectMultiple] = useState(false);
    const [transectionData, setTransectionData] = useState(0);
    const [showBulkActivationForm, setShowBulkActivationForm] = useState(0);
    const [showMultilineBulkActivationForm, setShowMultilineBulkActivationForm] = useState(false);
    const [showMultiLine, setShowMultiLine] = useState(false);
    const [searchData, setSearchData] = useState({
        statusID: '11',
        serialNumber: '',
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        aggregatorID: "",
        dealerID: "",
        networkID: ""
    });

    const [networkId, setNetworkId] = useState(0);

    const [infoData, setinfoData] = useState({
        eId: '',
        MSISDN: '',
        orderNumber: '',
        accountNumber: '',
        duplicateEmail: '',
        transactionId: '',
        Imagetobase64: '',
        ChannelTxnID: "",
        SerialNumber: '',
        ChildData: null
    });
    const [remarkContent, setRemarkContent] = useState({
        remark: '',
        errorCode: '',
    });
    const [updateStatusData, setUpdateStatusData] = useState({
        activationId: "",
        transactionId: "",
        serialNumber: "",
        // clientCode: "virtuzo",
        aggregatorTxnID: "",
        networkID: ""
    });
    const [planRentalPrice, setPlanRentalPrice] = useState(0);
    const [planRentalAddOn, setPlanRentalAddOn] = useState(0);
    const [prefferAggId, setPrefferAggId] = useState(0);
    const [month, setMonth] = useState(1);
    const [activationId, setActivationId] = useState(0);
    const [aggregatorID, setAggregatorID] = useState('');
    const [isPlanMultiline, setIsPlanMultiline] = useState(0);
    const [addressOp, setAddressOp] = useState([]);
    const [pincodeLoader, setPincodeLoader] = useState(false);
    const [statusData, setstatusData] = useState({
        tableName: "activationrequest",
        fieldName: "RequestStatus"
    });
    const [simType, setSimType] = useState(0);

    const { register: registerFilter, handleSubmit: handleSubmit, formState: { errors: filterError }, watch, reset } = useForm({ defaultValues: searchData });

    const statusForSearchID = watch('statusID');

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    useEffect(() => {
        if (!showMultiLine) {
            setEnableMultiLineUi(false);
        }
    }, [showMultiLine]);

    useEffect(() => {
        if (networkResult.length == 1) {
            reset({
                networkID: '1'
            });
        }
    }, [networkResult]);

    const {
        register: registerActivation,
        handleSubmit: handleActivationSubmit,
        formState: { errors: createActivationError },
        watch: watchActivation,
        reset: resetActivationField,
        setValue: setCreateActivationValue,
        control: setCreateActivationControl,
        clearErrors
    } = useForm({
        defaultValues: {
            tMobilePlanID: '',
            simType: '',
            month: '1',
            networkID: '',
            planID: '',
            serviceID: [],
            multiLine: '0',
            multilineData: [
                {
                    fname: "",
                    lname: "",
                    imeiNumber: "",
                    serialNumber: "",
                    pin: "",
                    simType: "",
                }
            ]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control: setCreateActivationControl,
        name: "multilineData",
    });

    const selectedAddon = watchActivation('serviceID');
    const multiLineCheckBox = watchActivation('multiLine');
    const MultilineData = watchActivation('multilineData');
    const activationIMEINumber = watchActivation('imeiNumber');
    const activationSIMNumber = watchActivation('simNumber');
    // const planForMonth = watchActivation('month');
    const planForMonth = '1';
    const zipCode = watchActivation('zipCode');
    const address1 = watchActivation('address1');
    const state = watchActivation('state');
    const simTypeWatched = watchActivation('simType');

    useEffect(() => {
        if (simTypeWatched) {
            setSimType(simTypeWatched);
        }
    }, [simTypeWatched]);

    useEffect(() => {
        if (pincodeLoader) {
            setTimeout(() => {
                setPincodeLoader(false);
            }, 600);
        }
    }, [pincodeLoader]);

    useEffect(() => {
        if (state) {
            clearErrors(['address1', 'city', 'zipCode']);
            setPincodeLoader(true);
            let dataOfThisState = getPrepaidIqAddressesData.filter((item) => item.State === state);
            let randomData = dataOfThisState[(Math.floor(Math.random() * dataOfThisState.length))];
            setCreateActivationValue('address1', randomData.Address);
            setCreateActivationValue('city', randomData.City);
            setCreateActivationValue('zipCode', randomData.ZIP);
        }
    }, [state]);

    const stateOptions = useMemo(() => {
        if (getPrepaidIqAddressesData.length > 0) {
            return [...new Set(getPrepaidIqAddressesData.map(item => item.State))];
        } else {
            return [];
        }
    }, [getPrepaidIqAddressesData]);



    const { register: registerComplete, handleSubmit: handleCompleteActivation, formState: { errors: completeError }, watch: watchComplete, reset: resetComplete, setValue } = useForm({ defaultValues: infoData });
    const { register: registerCancel, handleSubmit: handleCancelActivation, formState: { errors: cancelError }, watch: watchCancel, reset: resetCancel, } = useForm({ defaultValues: remarkContent });

    useEffect(() => {
        dispatch(getAggregator());
        dispatch(getDealer());
        const newData = {
            ...searchData,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
        };
        dispatch(getActivationRequestList(newData));
        dispatch(getShortCode(statusData));
        dispatch(getSimType({
            tableName: "activationrequest",
            fieldName: "SimType"
        }));
    }, [])


    useEffect(() => {
        tokenData && tokenData.length > 0 && tokenData.map((data => (
            setAccountBalance(data.AccountBalance)
        )))
    }, [tokenData])

    useEffect(() => {
        if (planForMonth == '') {
            setMonth(1);
        } else {
            setMonth(planForMonth);
        }
    }, [planForMonth]);


    useEffect(() => {

        if (updateActivationInBulkError !== null && updateActivationInBulkError !== undefined) {
            displayAlert(updateActivationInBulkError, 'alert-danger');
        }
        if (updateActivationInBulkMessage !== null && updateActivationInBulkMessage !== undefined) {
            displayAlert(updateActivationInBulkMessage, 'alert-success');
        }

        if (createSimSwapRequestError !== null && createSimSwapRequestError !== undefined) {
            displayAlert(createSimSwapRequestError, 'alert-danger');
        }
        if (createSimSwapRequestMessage !== null && createSimSwapRequestMessage !== undefined) {
            displayAlert(createSimSwapRequestMessage, 'alert-success');
        }

        if (activationError !== null && activationError !== undefined) {
            displayAlert(activationError, 'alert-danger');
        }
        if (activationMessage !== null && activationMessage !== undefined) {
            displayAlert(activationMessage, 'alert-success');
        }
        if (apiErrorModal !== null && apiErrorModal !== undefined) {
            displayAlert(apiErrorModal, 'alert-danger');
        }
        if (messageModal !== null && messageModal !== undefined) {
            displayAlert(messageModal, 'alert-success');
        }

        if (addBulkActivationError !== null && addBulkActivationError !== undefined) {
            displayAlert(addBulkActivationError, 'alert-danger');
        }
        if (addBulkActivationMessage !== null && addBulkActivationMessage !== undefined) {
            displayAlert(addBulkActivationMessage, 'alert-success');
        }

        if (addMultiLineBulkActivationError !== null && addMultiLineBulkActivationError !== undefined) {
            displayAlert(addMultiLineBulkActivationError, 'alert-danger');
        }
        if (addMultiLineBulkActivationMessage !== null && addMultiLineBulkActivationMessage !== undefined) {
            displayAlert(addMultiLineBulkActivationMessage, 'alert-success');
        }


    }, [activationError, activationMessage, apiErrorModal, messageModal, addBulkActivationError, addBulkActivationMessage,
        createSimSwapRequestError, createSimSwapRequestMessage, updateActivationInBulkMessage, updateActivationInBulkError,
        addMultiLineBulkActivationMessage, addMultiLineBulkActivationError]);

    const childTotal = useMemo(() => {
        if (multiLineCheckBox) {
            let addonChildTotal = 0;
            fields.forEach((item) => {
                if (item.addOnPrice) {
                    addonChildTotal += item.addOnPrice
                }
            });
            return ((childAmount * fields.length) + addonChildTotal);
        } else {
            return 0;
        }
    }, [fields, multiLineCheckBox, addonResult]);

    const childRentalTotal = useMemo(() => {
        if (multiLineCheckBox) {
            let addonChildTotal = 0;
            fields.forEach((item) => {
                if (item.rentalAddOnPrice) {
                    addonChildTotal += item.rentalAddOnPrice
                }
            });
            return ((planRentalPrice * fields.length) + addonChildTotal);
        } else {
            return 0;
        }
    }, [fields, multiLineCheckBox, addonResult]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetActivation());
        dispatch(getUserDataByToken());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetActivation());
        dispatch(getUserDataByToken());
    }

    useEffect(() => {
        dispatch(getAddonService());
        dispatch(getActiveNetwork());
        dispatch(getUserDataByToken())
        dispatch(getPlanAddonData())
    }, [])

    useEffect(() => {
        if (requestData && requestData.length > 0) {
            // var selectedActivation = props.requestData;
            var childDataView = '';
            if (requestData[0].Multiline === 1 && requestData[0].ChildActivation) {
                var childData = JSON.parse(requestData[0].ChildActivation);
                if (childData.length > 0) {
                    childDataView = childData.map((item, key) => (
                        <span key={key} className='line-2' style={{ border: 'none' }}>
                            <h4>Line {item.LineNumber}</h4>
                            <div className="row">
                                <div className="col-sm-3"><p>{item.IMEI}</p></div>
                                <div className="col-sm-2"><p>{item.SimType}</p></div>
                                <div className={item.SerialNumber.toString().length > 19 ? 'col-sm-5 serial-number-size-reduce' : 'col-sm-5'}><p>{item.SerialNumber}</p></div>
                                <div className="col-sm-2"><p>{item.MSISDN}</p></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3"><p style={{ color: 'red' }}>Add On Services</p></div>
                                {item.AddOnServices && item.AddOnServices.split(',').map((item, index) => {
                                    var serviceData = item.split('_');
                                    return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><small><em className="icon ni ni-check text-success"></em> {serviceData[0]}</small></span></div><div className='col-sm-2'><span><small>${serviceData[1]}</small></span></div></>
                                })}
                                {/* <div className="col-sm-9"><p>{item.AddOnServices}</p></div>    */}
                            </div>
                        </span>)
                    );
                }
            }

            setChildDataDisp(childDataView);
            setModalActivationData(requestData);

            // Multiline complete form changes
            if (requestData && requestData.length > 0) {
                var selectedReq = requestData && requestData;

                if (selectedReq[0].SimType === 'P-SIM') {
                    if (selectedReq[0].RequestType === "Activation") {
                        setDisableEID(true);
                        resetComplete(prevState => ({
                            ...prevState,
                            eId: "",
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                        }))
                        setchooseFileDisable(true);
                        setmobileDisable(false);
                    } else if (selectedReq[0].RequestType === 'PortIn') {
                        setDisableEID(true);
                        resetComplete(prevState => ({
                            ...prevState,
                            eId: "",
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                            MSISDN: selectedReq[0].MSISDN
                        }))
                        setchooseFileDisable(true);
                        setmobileDisable(true);
                    }
                } else if (selectedReq[0].SimType === 'E-SIM') {
                    if (selectedReq[0].RequestType === "Activation") {
                        setDisableEID(false);
                        resetComplete(prevState => ({
                            ...prevState,
                            eId: selectedReq[0].SerialNumber && selectedReq[0].SerialNumber !== "null" && selectedReq[0].SerialNumber !== "NULL" ? selectedReq[0].SerialNumber : '',
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                        }))
                        setmobileDisable(false);
                        setchooseFileDisable(false);
                    } else if (selectedReq[0].RequestType === 'PortIn') {
                        setDisableEID(false);
                        resetComplete(prevState => ({
                            ...prevState,
                            eId: selectedReq[0].SerialNumber && selectedReq[0].SerialNumber !== "null" && selectedReq[0].SerialNumber !== "NULL" ? selectedReq[0].SerialNumber : '',
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                            MSISDN: selectedReq[0].MSISDN
                        }));

                        setmobileDisable(true);
                        setchooseFileDisable(false);
                    }
                }
                if (selectedReq[0].Multiline === 1) {
                    const parseChildData = selectedReq[0].ChildActivation ? JSON.parse(selectedReq[0].ChildActivation) : []
                    setchildFormDataShow(parseChildData);
                    setinfoData(prevState => ({
                        ...prevState,
                        ChildData: parseChildData.map(child => ({
                            ID: child.ID,
                            SerialNumber: child.SerialNumber,
                            IMEI: child.IMEI,
                            MSISDN: '',
                            LineNumber: child.LineNumber,
                            SimTypeID: child.SimTypeID
                        })),
                    }));
                } else {
                    setinfoData(prevState => ({
                        ...prevState,
                        ChildData: null,
                    }));
                }
            }

        }
    }, [requestData]);

    useEffect(() => {
        setPrefferAggId(0);
        let selectedNetwork = networkResult && networkResult.filter(network => network.ID == networkId);
        if (selectedNetwork && selectedNetwork.length > 0) {
            // setPrefferAggId(selectedNetwork[0].PrefferedAggregator);
        }
        dispatch(getPlan(networkId));
    }, [networkId]);

    useEffect(() => {
        if (planResult.length > 0 && networkResult.length > 0) {
            const networkData = networkResult && networkResult.filter(network => network.ID == networkId);
            const aggregatorID = networkData && networkData.length > 0 && networkData[0].PrefferedAggregator;
            setSelectedPlan(planResult);
            setCreateActivationValue('aggregatorID', aggregatorID);
        } else {
            setSelectedPlan([]);
        }
        setPlanAddonResultData([]); // for now add on will only comes in T-Mobile aggregator
    }, [planResult]);

    const handleFilterChange = (value) => {
        setFilterText(value);
    };
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const handleOpenAddFormModal = () => {
        setPrefferAggId(0);
        setPlanAddonSelectedOption(false);
        setSelectedPlan([]);
        resetActivationField({
            fname: '',
            lname: '',
            city: '',
            state: '',
            address1: '',
            month: '',
            networkID: '',
            planID: '',
            serviceID: '',
            zipCode: '',
            imeiNumber: '',
            simNumber: '',
            customerEmail: '',
            pin: '',
            multiLine: '',
            wifiEnable: '',
            simType: '',
            multilineData: [
                {}
            ]
        });
        dispatch(prepareActivationModal());
        // setPlanFromData(emptyData);
        // resetActivationField();
        setFormButton('Confirm')
        setFormTitle('Add Activation')
        setEnableCallingUI(false);
        setEnableMultiLineUi(false);
        setPlanAmount(0);
        setAddonAmount(0);
        // setMultiAddonAmount(0);
    }

    const handleCancel = () => {
        dispatch(closeActivationModal());
        // resetActivationField();
    }

    const onSubmit = (data) => {
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        dispatch(getActivationRequestList(newData));
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }

    const handleCreateActivationSubmit = (data) => {
        let wifiCalling;
        let multiLine;
        let WifiZipCode;
        let address2;

        const foundNetwork = networkResult.find(network => network.ID == data.networkID);

        if (foundNetwork.PrefferedAggregator == 3) {
            data.address1 = data.address1 ? data.address1 : '';
            data.address2 = data.city ? data.city : '';
            data.WifiZipCode = data.zipCode ? data.zipCode : '';

            wifiCalling = 1;
            multiLine = data.multiLine ? Number(data.multiLine) : 0;
            WifiZipCode = data.zipCode;
            address2 = data.city;
        } else {
            data.address1 = data.address1 ? data.address1 : '';
            data.address2 = data.address2 ? data.address2 : '';
            data.WifiZipCode = data.WifiZipCode ? data.WifiZipCode : '';

            wifiCalling = 1;
            multiLine = data.multiLine ? Number(data.multiLine) : 0;
            WifiZipCode = data.WifiZipCode;
            address2 = data.address2 + ' ' + WifiZipCode
        }

        let multiData = data.multilineData && data.multilineData.length > 0 ? data.multilineData.map((item) => {
            const resultMultiArray = item.serviceID && item.serviceID.length > 0 && item.serviceID.map(id => {
                const serviceData = addonResult.find(item => item.ID === id);
                return { id, ServiceName: serviceData ? serviceData.ServiceName : null };
            });
            const joinedServiceNames = resultMultiArray && resultMultiArray.length > 0 && resultMultiArray.map(item => item.ServiceName).join(',');
            item.serviceID1 = item.serviceID ? joinedServiceNames : [];
            item.serviceID = item.serviceID ? item.serviceID : [];
            item.tMobileAddonID = item.tMobileAddonID ? item.tMobileAddonID : [];
            item.CartValue = item.CartValue ? item.CartValue : planAmount;
            return item;
        }) : [];

        let submitActivationData = {
            simType: data.simType,
            fname: data.fname ? data.fname : 'John',
            lname: data.lname ? data.lname : 'Doe',
            state: data.state ? data.state : '',
            city: data.city ? data.city : '',
            month: data.month ? data.month : '1',
            zipCode: data.zipCode,
            imeiNumber: data.imeiNumber,
            simNumber: data.simNumber,
            networkID: data.networkID,
            planID: data.planID,
            serviceID: data.serviceID ? data.serviceID : [],
            wifiCalling: wifiCalling,
            multiLine: data.multiLine,
            address1: data.address1,
            address2: address2,
            customerEmail: data.customerEmail,
            pin: data.pin,
            multiLine: multiLine,
            multilineData: multiData,
            tMobilePlanID: data.tMobilePlanID,
            tMobileAddonID: data.tMobileAddonID ? data.tMobileAddonID : [],
            purchaseAmount: (planAmount + addonAmount + multiAddonAmount + childTotal) * month,
            // aggregatorID: data.aggregatorID,
            rentalPrice: planRentalPrice + planRentalAddOn + childRentalTotal,
        }

        setSubmitActivationData(submitActivationData);

        const networkName = foundNetwork ? foundNetwork.NetworkName : null;
        const foundPlan = planResult.find(plan => plan.PlanID == data.planID);
        const planName = foundPlan ? foundPlan.PlanName : null;
        const resultServiceArray = data.serviceID && data.serviceID.length > 0 && data.serviceID.map(id => {
            const service = addonResult.find(item => item.ID === id);
            return { id, ServiceName: service ? service.ServiceName : null };
        });

        const joinedServiceNames = resultServiceArray && resultServiceArray.length > 0 && resultServiceArray.map(item => item.ServiceName).join(',');

        const displayConfirmModalData = {
            fname: data.fname ? data.fname : '',
            lname: data.lname ? data.lname : '',
            state: data.state ? data.state : '',
            city: data.city ? data.city : '',
            month: data.month,
            zipCode: data.zipCode,
            imeiNumber: data.imeiNumber,
            simNumber: data.simNumber,
            networkName: networkName,
            planName: planName,
            serviceID: joinedServiceNames,
            wifiCalling: wifiCalling,
            multiLine: data.multiLine,
            address1: data.address1,
            address2: address2,
            wifiZipCode: WifiZipCode,
            customerEmail: data.customerEmail,
            pin: data.pin,
            // multiLine: multiLine,
            multilineData: data.multilineData,
            purchaseAmount: planAmount + addonAmount + multiAddonAmount + childTotal,
            rentalPrice: planRentalPrice + planRentalAddOn + childRentalTotal,
        }
        setConfirmationData(displayConfirmModalData);
        dispatch(prepareConfirmationModal());

    }

    const handleSubmitCreateActivation = () => {
        const newSearchData = {
            ...searchData,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
        };
        dispatch(createActivation(submitActivationData, newSearchData));
    }

    const handleChangeWifiCalling = () => {
        setEnableCallingUI(enableCallingUI === false ? true : false)
    }

    const handleChangeMultiLine = () => {
        setEnableMultiLineUi(enableMultiLineUi === false ? true : false)
        // resetActivationField({
        //     multilineData: [
        //         {}
        //     ]
        // });
    }

    const handleNetwork = (e) => {
        e.preventDefault();
        resetActivationField({
            planID: '',
            serviceID: '',
            multiLine: '0',
        });
        setIsPlanMultiline(0);
        dispatch(getPlan(e.target.value));
        setNetworkId(e.target.value);
    }

    const handleSelectPlan = (e) => {
        resetActivationField({
            serviceID: ''
        });
        const singlePlanCategory = ['Connect by T-Mobile', 'Mobile Internet Plans'];
        const multiPlanCategory = ['T-Mobile Prepaid'];
        const planId = e.target.value;

        if (planId !== null && planId !== "") {
            const selectedData = planResult && planResult.filter(plan => plan.PlanID == planId);
            setPrefferAggId(selectedData[0].PrefferedAggregator);
            setChildAmount(selectedData[0].ChildPrice);

            const planAddon = planAddonResult && planAddonResult.filter(item => item.PlanID == planId)
            setPlanAddonResultData(planAddon);
            setIsPlanMultiline(selectedData[0].MultilineFlag);
            if (selectedData[0].MultilineFlag == 0) {
                setCreateActivationValue('multiLine', '0');
            }

            if (multiPlanCategory.includes(selectedData[0].PlanCategory)) {
                setShowMultiLine(true);
            } else {
                setShowMultiLine(false);
            }

            if (selectedData[0].PlanCategory) {
                if (singlePlanCategory.includes(selectedData[0].PlanCategory)) {
                    setAddOnSelectMultiple(false);
                } else if (multiPlanCategory.includes(selectedData[0].PlanCategory)) {
                    setAddOnSelectMultiple(true);
                }
                setPlanAddonSelectedOption(true);
            } else {
                setPlanAddonSelectedOption(false)
            }

            setPlanAmount(selectedData[0].PlanPrice);
            setPlanRentalPrice(selectedData[0].PlanRentalPrice);

            dispatch(getPrepaidIqAddresses({
                prefferedAggregator: selectedData[0].PrefferedAggregator
            }));

            setCreateActivationValue('state', '');
            setCreateActivationValue('address1', '');
            setCreateActivationValue('city', '');
            setCreateActivationValue('zipCode', '');

            if (selectedData[0].PrefferedAggregator == 3) {
                setCreateActivationValue('tMobilePlanID', selectedData[0].PrepaidIQPlanID);
            } else {
                setCreateActivationValue('tMobilePlanID', selectedData[0].TMobilePlanID);
            }

        } else {
            setPlanAmount(0);
            setPlanRentalPrice(0);
        }
    }

    const handleSelectAddon = (e) => {
        let valuesArray = e.map(item => item.value);
        let tMobileArray = e.map(item => item.tmobileAddonId)

        setCreateActivationValue('serviceID', valuesArray);
        setCreateActivationValue('tMobileAddonID', tMobileArray);

        const resultServiceArray = valuesArray.map(id => {
            const service = addonResult.find(item => item.ID === id);
            return service ? { id, ServiceName: service.ServiceName, ServicePrice: service.ServicePrice, RentalServicePrice: service.RentalServicePrice } : null;
        });

        const filteredResultServiceArray = resultServiceArray.filter(service => service !== null);
        const totalServicePrice = filteredResultServiceArray.reduce((total, service) => total + (service.ServicePrice || 0), 0);
        const totalRentalServicePrice = filteredResultServiceArray.reduce((total, service) => total + (service.RentalServicePrice || 0), 0);
        setPlanRentalAddOn(totalRentalServicePrice);
        setAddonAmount(totalServicePrice);
    }

    const handleLockActivation = (activationID) => {
        const lockActivationData = {
            activationId: activationID,
        }
        searchData.fromDate = moment(searchData.fromDate).format('YYYY-MM-DD');
        searchData.toDate = moment(searchData.toDate).format('YYYY-MM-DD');
        dispatch(lockActivation(lockActivationData, searchData));
    }

    const handleCompleteModalOpen = (id) => {
        dispatch(prepareCompleteModal())
        dispatch(accessTransactionModal(id));
        seteditId(id);
        setFormButton('Submit')
        setFormTitle('Complete Activation')
    }

    const handleUnLockActivation = (activationID) => {
        const unLockData = {
            activationId: activationID,
        }
        dispatch(unLockActivation(unLockData, searchData));
    }

    const handleRemarkModalOpen = (id) => {
        setFormButton('Submit');
        setFormTitle('Cancel Activation');
        seteditId(id);
        resetCancel();
        dispatch(prepareCancelModal());
        dispatch(getErrorCode());
    }
    const handleCancelComplete = () => {
        dispatch(closeCompleteModal())
    }

    const handleCompressedUpload = (image, callback) => {
        new Compressor(image, {
            quality: 0.2,
            success: (compressedResult) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    callback(compressedResult, base64String);
                };
                reader.readAsDataURL(compressedResult);
            },
        });
    };

    const handleImageChange = (e) => {
        setErrorImage("")
        const uploadImage = e.target.files[0];
        setSelectedFile(uploadImage);
        // setImagePreview(false);

        handleCompressedUpload(uploadImage, (compressedResult, base64String) => {
            if (compressedResult) {
                const image = new Image();
                image.onload = () => {
                    setValue('Imagetobase64', base64String);
                    setSelectedFileImage(base64String)
                };
                image.src = URL.createObjectURL(uploadImage);
            }
        });
    };

    const handleCompleteSubmit = (data) => {
        if (!chooseFileDisable) {
            if (data.Imagetobase64 === "") {
                setErrorImage("Image is Required !")
            }
        } else {
            dispatch(compeleteActivation(data, editId, searchData))
        }
    }

    const handleAddMoreForms = () => {
        setFormCount(formCount + 1);
        append({
            imeiNumber: "",
            serialNumber: "",
            pin: "",
            serviceID: []
        });
    };

    const handleDeleteForm = (index) => {
        remove(index)
        setFormCount(formCount - 1);
        // Additional cleanup or state updates if needed
    };

    const handleCancelRemark = () => {
        dispatch(closeCancelModal())
    }

    const handleCancelSubmit = (data) => {
        dispatch(cancelActivation(data, editId, searchData))
    }

    const handleCloseAlert = () => {
        dispatch(resetActivation());
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
        };
        dispatch(getActivationRequestList(newData));
    }

    const handleOpenStatusModal = (activationId, transactionId, serialNumber, networkID, multiline, aggregatorTxnID, aggregatorID) => {
        /*
        dispatch(prepareStatusModel());
        setUpdateStatusData(prevState => ({
            ...prevState,
            activationId: activationId,
            transactionId: transactionId,
            serialNumber: serialNumber,
            networkID: networkID,
            multiline: multiline,
            aggregatorTxnID: aggregatorTxnID
        }))*/
        let req = {
            activationId: activationId,
            transactionId: transactionId,
            serialNumber: serialNumber,
            networkID: networkID,
            multiline: multiline,
            aggregatorTxnID: aggregatorTxnID,
            aggregatorID: aggregatorID,
        };

        // let searchInputData = {
        //     statusID: statusForSearchID,
        //     fromDate: moment(fromDate).format('YYYY-MM-DD'),
        //     toDate: moment(toDate).format('YYYY-MM-DD')
        // }
        dispatch(updateActivationStatus(req, searchData, activationId));
    }

    const handleCloseUpdateModal = () => {
        dispatch(closeStatusModel())
    }

    const handleUpdateStatus = () => {
        let searchInputData = {
            statusID: statusForSearchID,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD')
        }
        dispatch(updateActivationStatus(updateStatusData, searchInputData));
    }

    const handleChangeIMEINumber = (e) => {
        dispatch(resetValidateMessage())
        const value = e.target.value
        if (value.length >= 14) {
            dispatch(validateImeiNumber({
                IMEI: value
            }))
        }
    }

    const handleTransactionIdModel = (item) => {
        setTransectionData(item);
        setAggregatorID(item.AggregatorID)
        dispatch(transactionIdModal());
        dispatch(accessTransactionModal(item.ID));
    };

    const handleCloseTransectionIdModal = () => {
        dispatch(closeTransactionIdModal());
    };

    //REQUEST INFORMATION
    const planIformationData = useMemo(() => {
        if (requestData) {
            let addonData = requestData[0].AddOnServices.split(',').map((val) => { return val.split('_'); });
            addonData = removeSameElementFromArray(addonData);
            let addonHtml = addonData.map((addonItem) => {
                return <lable><CheckLineIcon color='green' />{addonItem[0]}&emsp; - &emsp;${addonItem[1]}<br /></lable>
            })
            if (requestData[0].NetworkName == "T-Mobile") {
                return [
                    {
                        name: "Plan Category",
                        value: requestData[0].PlanCategory,
                    },
                    {
                        name: "Plan Name",
                        value: requestData[0].PlanName,
                    },
                    {
                        name: "Plan Price",
                        value: '$' + requestData[0].PlanPrice,
                    },
                    {
                        name: "Plan Month",
                        value: requestData[0].PlanMonth + ' Months',
                    },
                    {
                        name: "Add On Services",
                        value: addonHtml,
                    },
                ]
            }
            else {
                return [
                    {
                        name: "Plan Name",
                        value: requestData[0].PlanName,
                    },
                    {
                        name: "Plan Price",
                        value: '$' + requestData[0].PlanPrice,
                    },
                    {
                        name: "Plan Month",
                        value: requestData[0].PlanMonth + ' Months',
                    },
                ]
            }
        } else {
            return [];
        }
    }, [requestData]);

    const childData = useMemo(() => {
        if (requestData && requestData[0].ChildActivation) {
            let childDataArr = JSON.parse(requestData[0].ChildActivation).map((item) => {
                return {
                    IMEI: item.IMEI,
                    Serial: item.SerialNumber,
                    SimType: item.SimType,
                    Mobile: item.MSISDN,
                    AddOnServices: '',
                    CallerID: ''
                }
            });
            return childDataArr;
        } else {
            return [];
        }
    }, [requestData]);

    const selectOptions = planAddonResultData && planAddonResultData.length > 0 ? planAddonResultData.map(addon => ({
        label: addon.ServiceName,
        value: addon.AddonID,
        tmobileAddonId: prefferAggId == 3 ? addon.PrepaidIQAddonID : addon.TMobileAddonID
    })) : [];

    let columns;

    if ((isAdmin(tokenData) || isAdminAgent(tokenData))) {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'ACTION', key: 'action' },
            { displayName: 'STATUS', key: 'masterStatus' },
            { displayName: 'TRANSACTION ID', key: 'transactionId' },
            { displayName: 'IMEI', key: 'imei' },
            { displayName: 'SERIAL/EID', key: 'serialNumber' },
            { displayName: 'DEALER', key: 'dealer' },
            { displayName: 'DATE', key: 'requestedDtTm' },
            { displayName: 'NETWORK', key: 'NetworkName' },
            { displayName: 'AGGREGATOR', key: 'AggregatorName' },
            // { displayName: 'ORDER NUMBER', key: 'orderNumber' },
            { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
            // { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
            { displayName: 'TYPE', key: 'requestType' },
            { displayName: 'SIM', key: 'simType' },
            { displayName: 'REMARK', key: 'remark' },
        ];
    } else {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'ACTION', key: 'action' },
            { displayName: 'STATUS', key: 'masterStatus' },
            { displayName: 'TRANSACTION ID', key: 'transactionId' },
            { displayName: 'IMEI', key: 'imei' },
            { displayName: 'SERIAL/EID', key: 'serialNumber' },
            { displayName: 'NETWORK', key: 'NetworkName' },
            { displayName: 'DATE', key: 'requestedDtTm' },
            { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
            { displayName: 'DEALER', key: 'dealer' },
            { displayName: 'TYPE', key: 'requestType' },
            { displayName: 'SIM', key: 'simType' },
            { displayName: 'REMARK', key: 'remark' },
        ];
    }

    const filteredItems = activationRequestResult && activationRequestResult.length > 0 && activationRequestResult.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const data = useMemo(() => {
        if (filteredItems && filteredItems.length) {
            return filteredItems.map((activation, key) => {
                return {
                    key: key + 1,
                    action: <>
                        {activation.CustomerSupport === 0 ?
                            (activation.RequestStatusID == 11 ? (activation.activationUpdateLoading ? <div className="spinner-border spinner-border-sm text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : <div className="icon iq-action-icon-box rounded-circle bg-info" onClick={() => handleOpenStatusModal(activation.ID, activation.TransactionID, activation.SerialNumber, activation.NetworkID, activation.Multiline, activation.AggregatorTxnID, activation.AggregatorID)}> <i className="ri-refresh-line"></i></div>)
                                : '')
                            :
                            isAdmin(tokenData) && activation.CustomerSupport === 1 && (activation.RequestStatusID == 27 ? (
                                activation.lockFlag === 0 ? <span>{(activation.lockFlag === 0) && (activation.otherLockFlag === 0) ?
                                    <span className='d-flex'>
                                        {
                                            activation.lockLoading ? <div className="spinner-border text-secondary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleLockActivation(activation.ID)}> <i className="ri-lock-line" ></i></div>
                                        }
                                    </span> :
                                    <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleLockActivation(activation.ID)}> <i className="ri-forbid-line"></i></div>}</span> :
                                    <div className='d-flex'>
                                        <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleCompleteModalOpen(activation.ID)}> <i className="ri-check-line"></i></div>
                                        {
                                            activation.cancelModalLoading ? <div className="spinner-border text-secondary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleRemarkModalOpen(activation.ID, activation.ChannelTxnID, activation.SerialNumber)}> <i className="ri-close-fill"></i></div>
                                        }
                                        {
                                            activation.releaseLoading ? <div className="spinner-border text-secondary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> :
                                                <div className="icon iq-action-icon-box rounded-circle bg-secondary" onClick={() => handleUnLockActivation(activation.ID)} style={{ 'cursor': 'pointer' }}> <i className="ri-lock-unlock-line"></i></div>
                                        }
                                        {/* {
                                           data.notifyPosLoading ? <em title="Release" className="icon icon-circle ni ni-eye bg-primary lo-be button-loader-spinner" style={{ 'cursor': 'pointer', marginLeft: '10px' }}>
                                               <div id="loading-bar-spinner-button" className="spinner-button"><div className="spinner-icon"></div></div>
                                           </em> : <em className="icon icon-circle icon bg-primary ni ni-notify" data-bs-toggle="tooltip" data-bs-placement="top" title="Complete" style={{ 'cursor': 'pointer', marginLeft: '10px' }} onClick={() => handleNotifyPos(data.ID)}></em>
                                       } */}
                                    </div>
                            ) : ('')
                            )}
                    </>,
                    serialNumber: activation.SerialNumber,
                    NetworkName: activation.NetworkName,
                    AggregatorName: activation.AggregatorID,
                    transactionId: <Link to="#" style={activation.Multiline ? styles.multilineActivation : { color: 'blue' }} onClick={() => handleTransactionIdModel(activation)}>
                        <span data-toggle="tooltip" data-placement="top" title="View">
                            {activation.TransactionID}
                        </span>
                    </Link>,
                    imei: activation.IMEI,
                    dealer: activation.DealerName,
                    mobileNumber: activation.MSISDN,
                    requestedDtTm: activation.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(activation.RequestedDtTm) : '',
                    requestType: activation.RequestType,
                    simType: activation.SimType,
                    masterStatus: <Tag color={`${(activation.RequestStatusID === 13) ? 'badge-danger' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22) || activation.RequestStatusID === 27) ? 'badge-warning' : (activation.RequestStatusID === 12) ? 'badge-success' : (activation.RequestStatusID === 32) ? 'badge-info' : 'badge-secondary'}`} title={`${(activation.RequestStatusID === 13) ? 'Cancelled' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22)) ? 'Processing' : (activation.RequestStatusID === 12) ? 'Processed' : activation.RequestStatusID === 27 ? 'Assigned to Care' : (activation.RequestStatusID === 32) ? 'Picked' : 'Pending'}`}> </Tag>,
                    requestStatus: (<span className={`${(activation.RequestStatusID === 13 || activation.RequestStatusID === 24 || activation.RequestStatusID === 32) ? 'text-danger' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22) || activation.RequestStatusID === 27) ? 'text-warning' : (activation.RequestStatusID === 12) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{activation.RequestStatus}</strong></span>),
                    remark: activation.Remark,
                }
            });

        } else {
            return [];
        }
    }, [filteredItems]);

    const backBulkActivationForm = () => {
        setShowBulkActivationForm(0);
    }

    const handleMultilineSelectAddon = (e, index) => {
        let newValue = MultilineData.map((item, i) => {
            if (i == index) {
                let multiAddOnTotal = 0;
                let multiRentalAddOnTotal = 0;
                let valuesArray = e.map(item => item.value);
                let tMobileArray = e.map(item => item.tmobileAddonId);
                valuesArray.forEach((addOnID) => {
                    let addOn = addonResult.filter((it) => it.ID == addOnID);
                    if (addOn.length > 0 && addOn[0].ServicePrice) {
                        multiAddOnTotal += addOn[0].ServicePrice;
                        multiRentalAddOnTotal += addOn[0].RentalServicePrice;
                    }
                });
                return {
                    ...item,
                    ['serviceID']: valuesArray,
                    ['tMobileAddonID']: tMobileArray,
                    ['CartValue']: planAmount + multiAddOnTotal,
                    ['rentalPrice']: planRentalPrice + multiRentalAddOnTotal,
                    ['addOnPrice']: multiAddOnTotal,
                    ['rentalAddOnPrice']: multiRentalAddOnTotal,
                }
            } else {
                return item;
            }
        });
        setCreateActivationValue('multilineData', newValue);
    }

    const handleSimSwapModal = (activationID) => {
        setActivationId(activationID);
        dispatch(openSimSwapModal());
    }

    const showSimSwapModal = () => <SimSwapModalComponent activationId={activationId} />;

    const handleUpdateActivationInBulk = () => {
        dispatch(updateActivationInBulk(searchData));
    }

    const exportToCSV = async () => {
        if (activationRequestResult.length <= 0) {
            alert('No data for export');
            return;
        }
        let id = 0;
        let formatData = activationRequestResult && activationRequestResult.length > 0 && activationRequestResult.map((data, ind) => {
            id = id + 1;
            let newData = {
                ID: id,
                RequestStatus: data.RequestStatus,
                TransactionID: data.TransactionID,
                IMEI: data.IMEI,
                SerialNumber: data.SerialNumber,
                DealerName: data.DealerName,
                RequestedDtTm: moment(data.RequestedDtTm).format(LONG_TIME_FORMAT),
                NetworkName: data.NetworkName,
                MSISDN: data.MSISDN,
                RequestType: data.RequestType,
                SimType: data.SimType,
                Remark: data.Remark,
                PlanName: data.PlanName,
                PlanPrice: data.PlanPrice,
                PlanCategory: data.PlanCategory,
                PlanMonth: data.PlanMonth,
                WiFiCalling: data.WiFiCalling == 1 ? 'Yes' : 'No',
                Multiline: data.Multiline == 1 ? 'Yes' : 'No',
                PIN: data.PIN,
                AreaCode: data.AreaCode,
                ActivitySourceText: data.ActivitySourceText,
            }
            return newData
        })

        if (data && data.length > 0) {
            const ws = XLSX.utils.json_to_sheet(formatData);
            const wb = { Sheets: { 'activationResult': ws }, SheetNames: ['activationResult'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Activation_Request' + fileExtension);
        }
    }

    const handleBackConfirmation = () => {
        dispatch(backConfirmationModal());
    }

    return (
        <>
            {
                showBulkActivationForm === 1 ? <BulkActivationForm back={backBulkActivationForm} searchData={searchData} /> :
                    showBulkActivationForm === 2 ? <MultilineBulkActivationForm back={backBulkActivationForm} searchData={searchData} /> :
                        <>
                            <CardLayout title="Activation Request">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">

                                        <div className="col-lg-2 col-md-4 mb-2">
                                            <label>Select Network</label>
                                            <select className="form-control" id="networkID" name="networkID" {...registerFilter("networkID", {
                                            })} >
                                                <option value="">All</option>
                                                {
                                                    networkResult && networkResult.length > 0 && networkResult.filter((item) => item.IsActive == 1).map((item, index) => {
                                                        return <option value={item.ID}>{item.NetworkName}</option>
                                                    })
                                                }
                                            </select>
                                        </div>

                                        {
                                            (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                                            <div className="col-lg-2 col-md-4 mb-2">
                                                <label>Select Aggregator</label>
                                                <select className="form-control" id="aggregatorID" name="aggregatorID"  {...registerFilter("aggregatorID", {
                                                })} >
                                                    <option value="" name="All">All</option>
                                                    {
                                                        aggregatorList && aggregatorList.length && aggregatorList.filter((item) => item.IsActive).map((item) => {
                                                            return <option value={item.ID} key={`aggregator${item.ID}`}>{item.ID}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }

                                        <div className="col-lg-2 col-md-4 mb-2">
                                            <label>Select Status</label>
                                            <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...registerFilter("statusID", {
                                            })} >
                                                {shortCodeResult && shortCodeResult.length > 0 && <option value="" name="All">All</option>}
                                                <option value={'11'} name={'Processing'}>Processing</option>
                                                {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.filter(item => item.ID != 11).map((item, ind) => (
                                                    <option value={item.ID} name={item.Value}>{item.Value}</option>
                                                ))}
                                                {/* <option value="27" name="AssignedToCare">Assigned to Care</option>
                                        <option value="10" name="Pending">Pending</option>
                                        <option value="11" name="Processing">Processing</option>
                                        <option value="12, 23, 24" name="Processed">Processed</option>
                                        <option value="13" name="Cancelled">Cancelled</option> */}
                                            </select>
                                        </div>

                                        {
                                            (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                                            <div className="col-lg-2 col-md-4  mb-2">
                                                <label>Select Dealer</label>
                                                <select className="form-control" id="dealerID" name="dealerID"  {...registerFilter("dealerID", {
                                                })} >
                                                    <option value="" name="All">All</option>
                                                    {
                                                        dealerResult && dealerResult.length && dealerResult.map((item) => {
                                                            return <option value={item.ID} key={`dealer${item.ID}`}>{item.DealerName}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }

                                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                            <label>From Date</label>
                                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                            <DatePicker
                                                ref={datePickerRef}
                                                selected={fromDate}
                                                className="form-control"
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="yyyy-MM-dd"
                                                name="fromDate"
                                                showMonthDropdown
                                                showYearDropdown
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                value={fromDate}
                                            />
                                        </div>

                                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                            <label>To Date</label>
                                            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                            <DatePicker
                                                ref={datePickerRef2}
                                                selected={toDate}
                                                className="form-control center"
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="yyyy-MM-dd"
                                                name="todate"
                                                showMonthDropdown
                                                showYearDropdown
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                value={toDate}
                                            />
                                        </div>

                                        <div className="col-lg-2 col-md-4 mb-2">
                                            <label>IMEI/SIM/Mobile/TXN</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="serialNumber"
                                                name="serialNumber"
                                                placeholder="IMEI/SIM/Mobile/TXN"
                                                {...registerFilter("serialNumber")}
                                            />
                                        </div>

                                        <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? 'col-lg-2 col-md-4 mb-2' : 'col-lg-2 col-md-4'}>
                                            <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? "mt-2 d-flex justify-content-start activation-search-btn" : "mt-4"}>
                                                <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <hr />

                                <div className="row mb-3  ">
                                    <div className='col-lg-3  activation-request-search-btn mb-sm-444'>
                                        <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                                    </div>
                                    {/* <div className='col-lg-2' ></div> */}
                                    <div className='col-lg-9 d-flex justify-content-end activation-request-action-btn '>
                                        <div><Button onClick={handleUpdateActivationInBulk} loading={updateActivationInBulkLoading} title="Update Activation" icon={<RefreshLineIcon size={15} />} style="minWidth:120px" buttonClass={isDealer(tokenData) ? `btn acti-btn mr-2 font-weight-bold text-white` : `btn acti-btn font-weight-bold text-white`} /></div>
                                        {isDealer(tokenData) && <>
                                            <SideButtons title="Single Bulk Activation" className={'btn btn-success succ-btn-net font-weight-bold mr-2'} onClick={() => setShowBulkActivationForm(1)} />
                                            <SideButtons title="Multiline Bulk Activation" className={'btn multi-bulk-btn text-white font-weight-bold mr-2'} onClick={() => setShowBulkActivationForm(2)} />
                                            <SideButtons title="Activation" onClick={handleOpenAddFormModal} className="pri-btn-pri btn btn-primary" />
                                        </>}
                                        <div>
                                            <Button
                                                onClick={exportToCSV}
                                                title="Export"
                                                icon={<DownloadCloud2sLineIcon size={15} />}
                                                style="minWidth:120px"
                                                buttonClass={`btn acti-export-btn-v acti-ex-btn ml-2 font-weight-bold text-white`}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                                <div id={'activationRequestTable'}>
                                    <Table columns={columns} loading={activationListLoading} data={data} pagination={true} />
                                </div>

                                {/* This is for sim swap modal */}
                                {simSwapModal ? showSimSwapModal() : ''}

                                {/* create activation */}
                                <Modal
                                    showModal={activationFormModal}
                                    title={formTitle}
                                    onHide={handleCancel}
                                    width={700}
                                    showFooter={false}
                                >
                                    <form onSubmit={handleActivationSubmit(handleCreateActivationSubmit)}>
                                        <div className="form-row">

                                            <div className="col-md-4 mb-3">
                                                <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                                                <select className="form-control" placeholder="Select Network" name="networkID"
                                                    {...registerActivation("networkID", {
                                                        required: 'Network is required !',
                                                    })} onChange={(e) => { handleNetwork(e) }} >
                                                    <option value="" disabled>Select</option>
                                                    {networkResult.length > 0 && networkResult.map(network => (
                                                        <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                                    ))}
                                                </select>
                                                {createActivationError && createActivationError.networkID && (<div className="invalid-feedback">{createActivationError.networkID.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2"> Plan <span className='text-danger'>*</span></label>
                                                <select className="form-control" placeholder="Select Plan" name="planID"
                                                    {...registerActivation("planID", {
                                                        required: 'Plan is required !',
                                                    })}
                                                    onChange={(e) => handleSelectPlan(e)}>
                                                    <option value="" disabled key="0">Select</option>
                                                    {selectedPlan && selectedPlan.length > 0 && selectedPlan.map(plan => (
                                                        <option name="plan" disabled={plan.MultilineFlag === 1 && plan.ChildPrice === 0} value={plan.PlanID} key={plan.PlanID}>{`$${plan.PlanRentalPrice}`} - {plan.PlanName}</option>
                                                    ))}
                                                </select>
                                                {createActivationError && createActivationError.planID && (<div className="invalid-feedback">{createActivationError.planID.message}</div>)}
                                            </div>

                                            {planAddonSelectedOption ? <div className="col-md-4 mb-3">
                                                <label className="ml-2"> <span className='text-danger'></span> Addon Service </label>
                                                <Controller
                                                    control={setCreateActivationControl}
                                                    defaultValue={selectOptions.map(c => c.value)}
                                                    name="serviceID"
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Select
                                                            className="basic-multi-select"
                                                            placeholder="Select Service"
                                                            value={selectOptions.filter(c => value.includes(c.value))}
                                                            onChange={(e) => handleSelectAddon(e)}
                                                            options={selectOptions}
                                                            isOptionDisabled={() => !addOnSelectMultiple && selectedAddon && selectedAddon.length >= 1}
                                                            isMulti
                                                        />
                                                    )}
                                                />
                                            </div> : <div className="col-md-4 mb-3"></div>}

                                            <div className="col-md-4 mb-3">
                                                <label className='ml-2'> Sim Type <span className='text-danger'>*</span></label>
                                                <select className="form-control" name="simType"
                                                    {...registerActivation("simType", {
                                                        required: 'Sim type is required !',
                                                    })} >
                                                    <option value="" disabled>Select</option>
                                                    {shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.map((item) => {
                                                        return <option value={item.ID}>{item.Value}</option>
                                                    })}
                                                </select>
                                                {createActivationError && createActivationError.simType && (<div className="invalid-feedback">{createActivationError.simType.message}</div>)}
                                            </div>

                                            <div className={`col-md-4 mb-3 ${Styles.imeiInputGroup}`}>
                                                <label className='ml-2'>IMEI Number <span className='text-danger'> *</span></label>
                                                <input type="number" className="form-control" placeholder="Enter IMEI Number" name="imeiNumber"
                                                    {...registerActivation("imeiNumber", {
                                                        required: 'IMEI number is required!',
                                                        validate: (val) => {
                                                            if (!validateIMEI(val)) {
                                                                return "Invalid IMEI";
                                                            }
                                                            return true;
                                                        }
                                                    }
                                                    )}
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                />
                                                {createActivationError && createActivationError.imeiNumber && (<div className="invalid-feedback">{createActivationError.imeiNumber.message}</div>)}

                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className='ml-2'> SIM Number{simType == 25 ? <span className='text-danger'> *</span> : ''}</label>
                                                <input type="number" disabled={networkId == 0} className="form-control" placeholder="Enter SIM Number" name="simNumber"
                                                    {...registerActivation("simNumber", {
                                                        required: simType == 25 ? 'SIM number is required !' : false,
                                                        validate: {
                                                            commonValidation: (val) => {
                                                                if (simType == 25) {
                                                                    if (networkId == 3 || networkId == 4 || networkId == 6) {
                                                                        return val.length === 20 ? true : 'SIM number should be 20 digit !';
                                                                    } else {
                                                                        return val.length === 19 ? true : 'SIM number should be 19 digit !';
                                                                    }
                                                                } else if (simType == 26) {
                                                                    if (val == '') {
                                                                        return true;
                                                                    } else {
                                                                        return 'E-sim not required sim number'
                                                                    }
                                                                }
                                                            },
                                                        }
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {createActivationError && createActivationError.simNumber && (<div className="invalid-feedback">{createActivationError.simNumber.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2">PIN<span className='text-danger'> *</span></label>
                                                <input type="number" className="form-control" disabled={networkId == 0} placeholder="Enter Pin Number" name="pin" {...registerActivation("pin", {
                                                    required: 'Pin number is required !',
                                                    validate: (val) => {
                                                        if (networkId == 3 || networkId == 4) {
                                                            return val.length === 4 ? true : 'Pin must be of 4 digit';
                                                        } else {
                                                            return val.length === 6 ? true : 'Pin must be of 6 digit';
                                                        }
                                                    }
                                                })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {createActivationError && createActivationError.pin && (<div className="invalid-feedback">{createActivationError.pin.message}</div>)}

                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2"> Month <span className='text-danger'>*</span></label>
                                                <select className="form-control" name="month"
                                                    {...registerActivation("month", {
                                                        required: 'Month is required !',
                                                    })}>
                                                    <option value="" disabled key="0">Select</option>
                                                    <option value="1">1</option>
                                                    {/* <option value="2">2</option>
                                            <option value="3">3</option> */}

                                                </select>
                                                {createActivationError && createActivationError.month && (<div className="invalid-feedback">{createActivationError.month.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2">State <span className='text-danger'>*</span></label>
                                                <select className="form-control" name="state"
                                                    {...registerActivation("state", {
                                                        required: 'State is required !',
                                                    })} >
                                                    <option value="" disabled selected>Select</option>
                                                    {stateOptions.length > 0 && stateOptions.map((item, index) => (
                                                        <option value={item} key={`address${index}`}>{item}</option>
                                                    ))}
                                                </select>
                                                {
                                                    pincodeLoader === true ? <div className="spinner-border spinner-border-sm" style={{ position: "absolute", right: "10px", top: "40px", background: '#fff' }} role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : ''
                                                }
                                                {createActivationError && createActivationError.state && (<div className="invalid-feedback">{createActivationError.state.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2"> Address <span className='text-danger'>*</span></label>
                                                <input className="form-control" disabled placeholder="Enter Address" name="address1"
                                                    {...registerActivation("address1", {
                                                        required: 'Address is required !',
                                                    })} />
                                                {createActivationError && createActivationError.address1 && (<div className="invalid-feedback">{createActivationError.address1.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className="ml-2"> City <span className='text-danger'>*</span></label>
                                                <input className="form-control" disabled placeholder="Enter City" name="city"
                                                    {...registerActivation("city", {
                                                        required: 'City is required !',
                                                    })} />
                                                {createActivationError && createActivationError.city && (<div className="invalid-feedback">{createActivationError.city.message}</div>)}
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <label className='ml-2'> Zip Code <span className='text-danger'> *</span></label>
                                                <input type="number" disabled placeholder="Enter Zip Code" className="form-control"
                                                    name="zipCode" {...registerActivation("zipCode", {
                                                        required: 'Zip code is required !',
                                                        validate: (val) => {
                                                            if (getPrepaidIqAddressesData.filter((item) => item.ZIP == val).length > 0) {
                                                                return true;
                                                            } else {
                                                                return "Invalid zip code";
                                                            }
                                                        }
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {createActivationError && createActivationError.zipCode && (<div className="invalid-feedback">{createActivationError.zipCode.message}</div>)}
                                            </div>

                                        </div>

                                        {
                                            isPlanMultiline == 1 ?
                                                <div className='form-row'>
                                                    <div className="col-md-12 mb-3 od-2">
                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" id="customCheckbox2" value="1" name="customCheckbox2" className="custom-control-input" {...registerActivation('multiLine')} />
                                                            <label className="custom-control-label" htmlFor="customCheckbox2" onClick={handleChangeMultiLine}> Multi Line</label>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }

                                        {enableMultiLineUi && <div className='form-row'><div className='col-md-6'>
                                            <h4>Multi Line</h4>
                                        </div>
                                            {
                                                fields.length < 4 ? <div className='col-md-6'>
                                                    <div className='text-right row'>
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn btn-primary mr-2" onClick={handleAddMoreForms}>
                                                                Add More
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>}


                                        {enableMultiLineUi && (
                                            <div>
                                                {fields.map((field, index) => (
                                                    <>
                                                        <div className="card-1 my-3 p-2 z-3" key={field.id}>
                                                            <div className="form-row">
                                                                <div className="col-md-6 mb-3">
                                                                    <label className="ml-2"> IMEI Number <span className='text-danger'>*</span></label>
                                                                    <input
                                                                        type="number"
                                                                        placeholder="Enter IMEI Number"
                                                                        className="form-control"
                                                                        name={`imeiNumber`}
                                                                        {...registerActivation(`multilineData.${index}.imeiNumber`, {
                                                                            required: 'IMEI number is required!',
                                                                            validate: (val) => {
                                                                                if (!validateIMEI(val)) {
                                                                                    return "Invalid IMEI";
                                                                                }
                                                                                let imeiArrayList = MultilineData.filter((item, ind) => ind != index).map((item) => item.imeiNumber);
                                                                                if (activationIMEINumber != '') {
                                                                                    imeiArrayList.push(activationIMEINumber);
                                                                                }
                                                                                return !imeiArrayList.includes(val) ? true : "IMEI Should be unique";
                                                                            }
                                                                        })}

                                                                    />
                                                                    {createActivationError && createActivationError.multilineData && createActivationError.multilineData[index] && createActivationError.multilineData[index].imeiNumber && (<div className="invalid-feedback">{createActivationError.multilineData[index].imeiNumber.message}</div>)}
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <label className="ml-2"> Serial Number <span className='text-danger'>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter Serial Number"
                                                                        className="form-control"
                                                                        name={`serialNumber`}
                                                                        {...registerActivation(`multilineData.${index}.serialNumber`, {
                                                                            validate: (val) => {
                                                                                let simType = MultilineData.filter((item, ind) => ind == index);
                                                                                if (simType[0].simType == 26) {
                                                                                    if (val == '') {
                                                                                        return true;
                                                                                    } else {
                                                                                        return 'E-sim not required serial number, leave it blank'
                                                                                    }
                                                                                } else {

                                                                                    if (val == '') {
                                                                                        return "Serial number is required";
                                                                                    } else if (val.length < 19 && val.length > 19) {
                                                                                        return "Serial number should be 19 digit";
                                                                                    }

                                                                                    let SIMArrayList = MultilineData.filter((item, ind) => ind != index).map((item) => item.serialNumber);
                                                                                    if (activationSIMNumber != '') {
                                                                                        SIMArrayList.push(activationSIMNumber);
                                                                                    }
                                                                                    return !SIMArrayList.includes(val) ? true : "Serial Number Should be unique";
                                                                                }

                                                                            }
                                                                        })}
                                                                    />
                                                                    {createActivationError && createActivationError.multilineData && createActivationError.multilineData[index] && createActivationError.multilineData[index].serialNumber && (<div className="invalid-feedback">{createActivationError.multilineData[index].serialNumber.message}</div>)}
                                                                </div>

                                                                {fields.length > 1 && <i className={`ri-delete-bin-2-line ${Styles.multilineCloseIcon}`} style={{ fontSize: '20px', color: 'red', cursor: 'pointer', margin: '4px 5px' }} onClick={() => handleDeleteForm(index)}></i>}

                                                                <div className="col-md-4">
                                                                    <label className="ml-2">PIN<span className='text-danger'> *</span></label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Enter Pin Number"
                                                                        name={`pin`}
                                                                        {...registerActivation(`multilineData.${index}.pin`, {
                                                                            required: 'Pin number is required !',
                                                                        })}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                                    />
                                                                    {createActivationError && createActivationError.multilineData && createActivationError.multilineData[index] && createActivationError.multilineData[index].pin && (<div className="invalid-feedback">{createActivationError.multilineData[index].pin.message}</div>)}
                                                                </div>

                                                                <div className="col-md-5 ">
                                                                    {planAddonSelectedOption && <>
                                                                        <label className="ml-2"> <span className='text-danger'></span> Addon Service </label>
                                                                        <Controller
                                                                            control={setCreateActivationControl}
                                                                            name={`multilineData.${index}.serviceID`}
                                                                            render={({ field: { onChange, value, ref } }) => (
                                                                                <Select
                                                                                    className="basic-multi-select"
                                                                                    placeholder="Select Service"
                                                                                    value={value ? selectOptions.filter(c => value.includes(c.value)) : []}
                                                                                    onChange={(e) => handleMultilineSelectAddon(e, index)}
                                                                                    options={selectOptions}
                                                                                    isMulti
                                                                                />
                                                                            )}
                                                                        />
                                                                    </>}
                                                                </div>

                                                                <div className="col-md-3 mb-3">
                                                                    <label className="ml-2">Sim Type <span className='text-danger'> *</span></label>
                                                                    <select className="form-control" name={`multilineData.${index}.simType`}
                                                                        {...registerActivation(`multilineData.${index}.simType`, {
                                                                            required: 'Sim type is required !',
                                                                        })}>
                                                                        <option value="" disabled selected>Select</option>
                                                                        {
                                                                            shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.map((item) => {
                                                                                return <option value={item.ID} key={`simType${item.ID}`}>{item.Value}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {createActivationError && createActivationError.multilineData && createActivationError.multilineData[index] && createActivationError.multilineData[index].simType && (<div className="invalid-feedback">{createActivationError.multilineData[index].simType.message}</div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        )}

                                        <hr />
                                        <div className='d-flex justify-content-around bg-success ' style={{ padding: "8px", borderRadius: "4px" }}>
                                            <h5 className='font-weight-bold text-white  px-lg-4 px-1 '>Account Balance : $ {accountBalance}</h5>
                                            <h5 className='font-weight-bold text-white px-lg-4 px-1'>Purchase Amount : $ {(planAmount + addonAmount + childTotal) * Number(month)}</h5>
                                        </div>

                                        {accountBalance < (planAmount + addonAmount + childTotal) ? <p className='text-danger font-weight-bold text-center'>Insufficient account balance to make the purchase</p> : ""}

                                        <hr />
                                        <div className='text-right row'>
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                                                {validateSuccess || true ? (accountBalance < parseInt(planAmount + addonAmount + childTotal) ?
                                                    <Button title="Confirm" style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' disable={true} >NO</Button> :
                                                    <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />) :
                                                    <Button title="Confirm" style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' disable={true} >NO</Button>}
                                            </div>
                                        </div>
                                    </form>
                                </Modal>

                                {/* complete activation */}
                                <Modal
                                    showModal={completeModal}
                                    title={formTitle}
                                    onHide={handleCancelComplete}
                                    width={700}
                                    showFooter={false}
                                >
                                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                                    <form onSubmit={handleCompleteActivation(handleCompleteSubmit)}>
                                        <div className="form-row">
                                            <div className="col-md-12 mb-3">
                                                <label>EID</label>
                                                <input type="number" placeholder="Enter EID" className="form-control"
                                                    disabled={disableEID}
                                                    name="eId" {...registerComplete("eId", {
                                                        required: !disableEID,
                                                    })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {completeError.eId?.type === "required" && (
                                                    <div className="invalid-feedback">EID  required !</div>

                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>Mobile Number</label>
                                                <input type="number" className="form-control" placeholder="Enter Mobile Number" name="MSISDN"
                                                    disabled={mobileDisable}
                                                    {...registerComplete("MSISDN", {
                                                        required: !mobileDisable,
                                                    })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {completeError.MSISDN?.type === "required" && (
                                                    <div className="invalid-feedback">Mobile number is required !</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>Account Number</label>
                                                <input type="number" className="form-control" placeholder="Enter Account Number" name="accountNumber" {...registerComplete("accountNumber", {
                                                    required: true,
                                                })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {completeError.accountNumber?.type === "required" && (
                                                    <div className="invalid-feedback">Account number is required !</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>Order Number</label>
                                                <input type="number" className="form-control" placeholder="Enter Order Number" name="orderNumber" {...registerComplete("orderNumber", {
                                                    required: true,
                                                })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {completeError.orderNumber?.type === "required" && (
                                                    <div className="invalid-feedback">Order number is required !</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>Email ID</label>
                                                <input type="text" className="form-control" placeholder="Enter Email ID" name="duplicateEmail" {...registerComplete("duplicateEmail", {
                                                    pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                                })} />
                                                {completeError.duplicateEmail?.type === "pattern" && (
                                                    <div className="invalid-feedback">Email is not valid !</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>Transaction ID</label>
                                                <input type="number" className="form-control" placeholder="Enter Transaction ID Number" name="transactionId" {...registerComplete("transactionId", {
                                                    required: true,
                                                })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                {completeError.transactionId?.type === "required" && (
                                                    <div className="invalid-feedback"> Transaction ID is required !</div>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label>File Upload</label>
                                                <div className="form-group mb-0">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" accept=".jpg, .jpeg, .png" name='Imagetobase64'
                                                            disabled={chooseFileDisable}
                                                            onChange={e => handleImageChange(e)} />
                                                        <label className="custom-file-label">{selectedFile ? selectedFile.name : 'Choose file'}</label>
                                                    </div>
                                                    <div className="invalid-feedback">{errorImage}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className='text-right row'>
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelComplete}>Close</button>
                                                <Button title={formButton} style="minWidth:80px" buttonclassName="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                            </div>
                                        </div>
                                    </form>
                                </Modal>

                                {/* cancel activation */}
                                <Modal
                                    showModal={cancelModal}
                                    title={formTitle}
                                    onHide={handleCancelRemark}
                                    width={700}
                                    showFooter={false}
                                >
                                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                                    <form onSubmit={handleCancelActivation(handleCancelSubmit)}>
                                        <div className="form-row">
                                            <div className="col-md-12 col-lg-12 mb-2">
                                                <label>Error</label>
                                                <select className="form-control" id="errorCode" name="errorCode" placeholder="Error"  {...registerCancel("errorCode", {
                                                    required: true
                                                })} >
                                                    <option value="" name="All">Select</option>
                                                    {errorCodeResult && errorCodeResult.length > 0 && errorCodeResult.map((item => (
                                                        <option value={item.ErrorCode} name="All">{item.ErrorDesc}</option>
                                                    )))}

                                                </select >
                                                {cancelError.errorCode?.type === "required" && (
                                                    <div className="invalid-feedback">Select Error !</div>
                                                )}
                                            </div >
                                            <div className="col-md-12 mb-3">
                                                <label>Remark</label>
                                                <textarea className="form-control" placeholder="Enter Remark" id="remark" name="remark" rows="3" cols="30" {...registerCancel("remark", {
                                                    required: true
                                                })}>
                                                </textarea>
                                                {cancelError.remark?.type === "required" && (
                                                    <div className="invalid-feedback">Remark required !</div>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='text-right row'>
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelRemark}>Close</button>
                                                <Button title={formButton} style="minWidth:80px" buttonclassName="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                            </div>
                                        </div>
                                    </form>
                                </Modal>

                                {/* conformation modal */}
                                <Modal
                                    showModal={confirmationModal}
                                    title="Confirmation"
                                    onHide={handleCancel}
                                    width={700}
                                    showFooter={false}>

                                    {confirmationData && <div>
                                        <div className='border rounded'>
                                            <div className='row px-2 py-2'>
                                                <div className='col-md-3'>
                                                    <label>Network </label>
                                                    <p>{confirmationData.networkName}</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Plan </label>
                                                    <p>{confirmationData.planName}</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>IMEI Number </label>
                                                    <p>{confirmationData.imeiNumber}</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>SIM Number </label>
                                                    <p>{confirmationData.simNumber}</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>PIN </label>
                                                    <p>{confirmationData.pin}</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Zip Code </label>
                                                    <p>{confirmationData.zipCode}</p>
                                                </div>
                                                {confirmationData.address1 ? <div className='col-md-3'><label>Address</label> <p>{confirmationData.address1}</p> </div> : ''}
                                                {confirmationData.city ? <div className='col-md-3'> <label>City </label> <p>{confirmationData.city}</p> </div> : ''}
                                                {confirmationData.state ? <div className='col-md-3'> <label>State </label> <p>{confirmationData.state}</p> </div> : ''}
                                                <div className='col-md-12'>
                                                    <label>Addon Service</label>
                                                    <p>{confirmationData.serviceID}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {confirmationData.wifiCalling === 1 && <h5 className="font-weight-bold"># Wifi Calling</h5>}
                                        {confirmationData.wifiCalling === 1 && <div className='border my-2 rounded'>
                                            <div className='row px-2 py-2'>
                                                <div className='col-md-12'>
                                                    <span><label>Address 1</label> : <span>{confirmationData.address1}</span></span>
                                                </div>
                                                <div className='col-md-12'>
                                                    <span><label>Address 2</label> : <span>{confirmationData.address2}</span></span>
                                                </div>
                                                <div className='col-md-12'>
                                                    <span><label>Zip Code</label> : <span>{confirmationData.wifiZipCode}</span></span>
                                                </div>
                                            </div>
                                        </div>}
                                        {confirmationData.multiLine == 1 && <h5 className='font-weight-bold'># Multi Line</h5>}
                                        {confirmationData.multilineData && confirmationData.multilineData.length > 0 && confirmationData.multilineData.map((item, key) => {
                                            return (
                                                <div className='border my-2 rounded'>
                                                    <p className='font-weight-bold px-2'> Line :{key + 1}</p>
                                                    <hr />
                                                    <div className='row px-2'>
                                                        <div className='col-md-4'>
                                                            <label>Serial Number </label>
                                                            <p>{item.serialNumber}</p>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <label>IMEI Number </label>
                                                            <p>{item.imeiNumber}</p>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label>PIN </label>
                                                            <p>{item.pin}</p>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <label>Addon Service </label>
                                                            <p>{item.serviceID1 && item.serviceID1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className='d-flex justify-content-around bg-success' style={{ padding: "8px", borderRadius: "4px" }}>
                                            <h5 className='font-weight-bold text-white  px-4 '>Account Balance : $ {accountBalance}</h5>
                                            <h5 className='font-weight-bold text-white  px-4'>Purchase Amount : $ {(planAmount + addonAmount + childTotal + Object.values(multiAddonAmount).reduce((total, amount) => total + amount, 0)) * month}</h5>
                                        </div>
                                        <hr />
                                        <div className='text-right row'>
                                            <div className="col-md-12">
                                                {/* <button type="button" style={{minWidth:"80px",background:"#2980b9",color:"#fff"}} className="btn mr-2" onClick={() => handleBackConfirmation()}>Back</button> */}
                                                <button type="button" style={{ minWidth: "80px" }} className="btn btn-secondary mr-2" onClick={() => handleBackConfirmation()}>Back</button>
                                                <Button title="Submit" style="minWidth:80px" buttonClass="btn btn-primary" onClick={handleSubmitCreateActivation} loading={buttonLoading} buttonType='submit' />
                                            </div>
                                        </div>
                                    </div>}
                                </Modal>

                                {/* locked alert modal */}
                                <Modal
                                    showModal={LockFailAlert}
                                    title={"Lock Alert"}
                                    onHide={handleCancelRemark}
                                    width={500}
                                    showFooter={false}
                                >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <h4 className='text-center text-danger'>{LockedFailedmessage}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='text-right row'>
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCloseAlert}>Close</button>
                                        </div>
                                    </div>

                                </Modal>

                                {/* Update Actiuvation Status Modal */}
                                <Modal
                                    showModal={updateModal}
                                    title={"Update Status"}
                                    onHide={handleCloseUpdateModal}
                                    width={500}
                                    showFooter={false}
                                >
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <h4 className='text-center text-success font-normal'>Are your sure want to update the status ? </h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='text-right row'>
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCloseUpdateModal}>No </button>
                                            <Button title="Yes" style="minWidth:80px" buttonClass="btn btn-primary" onClick={handleUpdateStatus} loading={buttonLoading} buttonType='submit' />
                                        </div>
                                    </div>

                                </Modal>

                                {/* Transection View modal */}
                                <Modal
                                    showModal={transectionIdModal}
                                    title={`CHANNEL TXN ID: ${requestData ? requestData[0].TransactionID : ""}`}
                                    onHide={handleCloseTransectionIdModal}
                                    width={750}
                                    showFooter={false}
                                >
                                    {
                                        modalViewLoader ? <>
                                            <div className='container text-center'>
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </> :
                                            <>
                                                <div className="row text-dark px-4">
                                                    <div className="col-md-12">
                                                        <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> REQUEST INFORMATION</h5>
                                                        <hr />
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>IMEI</span>
                                                        <span className="text-end">{requestData && requestData[0].IMEI ? requestData[0].IMEI : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>SIM Type</span>
                                                        <span className="text-end">{requestData && requestData[0].SimType ? requestData[0].SimType : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Serial #</span>
                                                        <span className="text-end">{requestData && requestData[0].SerialNumber ? requestData[0].SerialNumber : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Zip Code</span>
                                                        <span className="text-end">{requestData && requestData[0].AreaCode ? requestData[0].AreaCode : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Activation Type</span>
                                                        <span className="text-end">{requestData && requestData[0].RequestType ? requestData[0].RequestType : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Mobile #</span>
                                                        <span className="text-end">{requestData && requestData[0].MSISDN ? requestData[0].MSISDN : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Account #</span>
                                                        <span className="text-end">{requestData && requestData[0].AccountNumber ? requestData[0].AccountNumber : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>Order #</span>
                                                        <span className="text-end">{requestData && requestData[0].OrderNumber ? requestData[0].OrderNumber : "N/A"}</span>
                                                    </div>

                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>T-Mobile PIN</span>
                                                        <span className="text-end">{requestData && requestData[0].PIN ? requestData[0].PIN : "N/A"}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                        <span>WiFi Calling </span>
                                                        <span className="text-end">{requestData && requestData[0].WiFiCalling ? 'Yes' : 'No'}</span>
                                                    </div>
                                                    <div className="col-md-8 d-flex justify-content-between mb-2">
                                                        <span>Customer Email</span>
                                                        <span className="text-end">{requestData && requestData[0].CustomerEmail ? requestData[0].CustomerEmail : "N/A"}</span>
                                                    </div>
                                                </div>

                                                {requestData && requestData.length > 0 && requestData[0].Multiline === 1 &&
                                                    <div>
                                                        <hr className="mx-4" />
                                                        <h5 className="col-md-12 px-4" style={{ fontWeight: "700" }}>CHILD ACTIVATION DATA</h5>
                                                        <hr className="mx-4 mb-0" />
                                                        {childData && childData.length > 0 && childData.map((item, index) => (
                                                            <fieldset className="border">
                                                                <legend> <span>Child {index + 2}:</span></legend>
                                                                <div className="row text-dark">
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>IMEI</span>
                                                                        <span>{item.IMEI}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Serial</span>
                                                                        <span>{item.Serial}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>SIM Type</span>
                                                                        {/* <span>{requestData && requestData.length > 0 && requestData[0].SimType}</span> */}
                                                                        <span>{item.SimType}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Mobile</span>
                                                                        <span>{item.Mobile != '' && item.Mobile ? item.Mobile : 'N/A'}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Add On Services</span>
                                                                        <span>{item.AddOnServices != '' ? item.AddOnServices : 'N/A'}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>CallerID</span>
                                                                        <span>{item.CallerID != '' ? item.CallerID : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        ))}
                                                    </div>}

                                                {aggregatorID == 2 && isAdmin(tokenData) ?
                                                    <div className="row text-dark px-4">
                                                        <div className="col-md-12">
                                                            <hr />
                                                            <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> DEALER INFORMATION </h5>
                                                            <hr />
                                                        </div>
                                                        <div className="col-md-12  d-flex justify-content-between mb-2">
                                                            <span>Dealer Name</span>
                                                            <span className="text-end">{requestData && requestData[0].DealerName ? requestData[0].DealerName : "N/A"}</span>
                                                        </div>
                                                        <div className="col-md-6  d-flex justify-content-between mb-2">
                                                            <span>TMobile Username</span>
                                                            <span className="text-end">{requestData && requestData[0].TMobileUsername ? requestData[0].TMobileUsername : "N/A"}</span>
                                                        </div>
                                                        <div className="col-md-6  d-flex justify-content-between mb-2">
                                                            <span>TMobile Password</span>
                                                            <span className="text-end">{requestData && requestData[0].TMobilePassword ? requestData[0].TMobilePassword : "N/A"}</span>
                                                        </div>
                                                        {/* <div className="col-md-6  d-flex justify-content-between mb-2">
                                            <span>EPay Username</span>
                                            <span className="text-end">{requestData && requestData[0].EPayUsername ? requestData[0].EPayUsername : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6  d-flex justify-content-between mb-2">
                                            <span>EPay Password</span>
                                            <span className="text-end">{requestData && requestData[0].EPayPassword ? requestData[0].EPayPassword : "N/A"}</span>
                                        </div> */}
                                                    </div>
                                                    : ''}


                                                <div className="row text-dark px-4">
                                                    <div className="col-md-12">
                                                        <hr />
                                                        <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}>
                                                            PLAN INFORMATION
                                                        </h5>
                                                        <hr />
                                                    </div>
                                                    {planIformationData && planIformationData.length > 0 &&
                                                        planIformationData.map((item, index) => (
                                                            <div className={index == 0 || (index == 1 && item.name != "Plan Price") || index == 4 ? `col-md-12 d-flex justify-content-between mb-2` : `col-md-6 d-flex justify-content-between mb-2`}>
                                                                <span>{item.name}</span>
                                                                <span className="text-end">{item.value}</span>
                                                            </div>
                                                        ))}
                                                </div>

                                                <hr className="mx-4" />

                                                {aggregatorID == 2 ? <div className="row text-dark px-4">
                                                    <div className="col-md-12">
                                                        <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> REQUEST JOURNEY </h5>
                                                        <hr />
                                                        {
                                                            requestData && requestData[0].logHistory && requestData[0].logHistory.length > 0 && requestData[0].logHistory.map((item) => {
                                                                let note = JSON.parse(item.Note);
                                                                return <>
                                                                    <div className="row">
                                                                        <div className='col-lg-4'>
                                                                            <ul className={`${Styles['sessions-mvo']} p-0`}>
                                                                                <li className={item.IsError == 1 ? `${Styles['timemvoline']} ${Styles['red']}` : `${Styles['timemvoline']} ${Styles['green']}`}>
                                                                                    <div className={Styles['time-mvo']}>{moment(item.CreatedDate).format(LONG_TIME_FORMAT)}</div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className='col-lg-8'>
                                                                            <ul className='p-0'>
                                                                                <li className=' align-items-baseline'>
                                                                                    <div className='d-flex'>
                                                                                        <span className='mr-1'><i className={item.IsError === 0 ? 'ri-checkbox-circle-line' : 'ri-close-circle-line'} style={{ fontSize: '19px', color: item.IsError === 0 ? '#1ee0ac' : '#e74c3c' }}></i>
                                                                                        </span>
                                                                                        <h6 className={Styles['timeline-titlemvno']}>{item.IsError === 0 ? (item.Note.charAt(0) != '{' ? item.Note : JSON.parse(item.Note).Value !== '' ? JSON.parse(item.Note).Value : null) : JSON.parse(item.Note).jarAssetName != undefined ? item.ErrorDesc + ' (' + JSON.parse(item.Note).jarAssetName + ')' : item.ErrorDesc} {item.Note.charAt(0) != '{' ? '' : JSON.parse(item.Note).RequestStatus !== "" ? <span className='mx-2 font-weight-normal' style={{ color: "#8094ae" }}>{JSON.parse(item.Note).RequestStatus === 18 ? '(' + JSON.parse(item.Note).MSISDN + ')' : JSON.parse(item.Note).RequestStatus === 12 ? '(' + item.OrderNumber + ')' : null}</span> : null}</h6>
                                                                                    </div>

                                                                                    {item.IsError === 1 ? <p className='ml-3' style={{ color: "#8094ae" }}>{JSON.parse(item.Note).ErrorDesc}</p> : ''}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            })
                                                        }

                                                    </div>
                                                </div> : ''}
                                            </>
                                    }
                                </Modal>

                            </CardLayout>
                        </>
            }
        </>
    )
}

const styles = {
    multilineActivation: {
        color: "#e74c3c",
        border: "1px solid",
        padding: "3px 5px",
        borderRadius: "9px"
    }
}

export default ActivationRequest;